import React, { useEffect, useState } from "react";
import "../../../styles/app.css";

function GeneralPhysicalExam({setNameForm, onFormSubmit}) {
  const [formState, setFormState] = useState({
    height: "",
    weight: "",
    gait: "",
    auxiliary: "",
    
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState({
      ...formState,
      [name]: type === "checkbox" ? checked : value,
    });
  };


  const [bmi, setBmi] = useState(null);

  useEffect(() => {
    if (formState.weight && formState.height) {
      
      const bmiValue = (formState.weight/ ((formState.height/100) * (formState.height/100))).toFixed(2);
      setBmi(bmiValue);
     } else {
       setBmi("");
     }
 }, [formState.weight, formState.height]);


  const handleSubmit = (e) => {
    e.preventDefault();
    // Prepare form details for summary
    setNameForm("Examen physique général");
    


    const formDetails = {
      // Here we convert the form state into a suitable format for display
    
      "Taille": formState.height ? `${formState.height} (cm)` : "",
      "Poids": formState.weight ? `${formState.weight} (kg)` : "",
      "BMI": bmi ? `${bmi} (kg/m²)` : "",
      "Démarche": formState.gait,
      "Moyen auxiliaire": formState.auxiliary,
      
    };




    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object


    // Call the function passed from App.js and pass the details
    onFormSubmit(filteredFormDetails); // Make sure to pass this function correctly from App.js

};

  return (
    <div className="card">
      <p className="title">Examen clinique général</p>
      <form className="specific-form" onSubmit={handleSubmit}>
      
      <div>
      <label>taille (cm) et poids:</label>
      <div>
          <input type="number" className="number-input" name="height" placeholder = "cm" min="1" max="230" value={formState.height} onChange={handleInputChange}/>
     
       <input type="number" className="number-input" name="weight" placeholder = "kg" min="1" max="300" value={formState.weight} onChange={handleInputChange}/>
      
       {bmi && (
            
            
              <input type="text" className="small-input" value={bmi + "kg/m²"} readOnly />
            
          )}

      </div>
      </div>



        <div>
          <label>Démarche:</label>
          <select name="gait" className="select-input" value={formState.gait} onChange={handleInputChange} >
            <option value="">-</option>
            <option value="aucune boiterie">aucune boiterie</option>
            <option value="boiterie antalgique">boiterie antalgique</option>
            <option value="boiterie de type Trendelburg">boiterie de type Trendelburg</option>
            <option value="démarche ataxique">démarche ataxique</option>
            <option value="AUTRE">AUTRE</option>
          </select>
        </div>


        <div>
          <label>Moyen auxiliaire:</label>
          <select name="auxiliary" className="select-input" value={formState.auxiliary} onChange={handleInputChange} >
            <option value="">-</option>
            <option value="aucun">aucun</option>
            <option value="une canne / un baton de de marche">une canne / un baton de marche</option>
            <option value="une canne anglaise">une canne anglaise</option>
            <option value="deux cannes anglaises">deux cannes anglaises</option>
            <option value="cadre de marche">cadre de marche</option>
            <option value="AUTRE">AUTRE</option>
          </select>
        </div>



        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default GeneralPhysicalExam;