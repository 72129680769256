import React, { useState } from "react";
import "../../../styles/app.css";

function Shoulder({setNameForm, onFormSubmit}) { 
  const [formState, setFormState] = useState({
 
    side: "",
    Amyotrophy: "",
    skin: "pas de plaie, pas de cicatrice",
    scar: "",
    
    AnteFlexionShoulder: "",
    RetroflexionShoulder: "",
    neutralEShoulder: "",

    abdShoulder: "",
    addShoulder: "",
    neutralAAShoulder: "",

    ReShoulder: "",
    RiShoulder: "",
    NeutralRotationShoulder: "",
    
    JobeTest:"",
    PalmTest: "",
    PatteTest: "",
    LiftOffTest:"",
    BellyPress: "",
    ImpingementTest: "",
    ApprehensionTest: ""

  });


  const defaultValues = {
   
    AnteFlexionShoulder: 180,
    RetroflexionShoulder: 60,
    neutralEShoulder: 0,

    abdShoulder: 180,
    addShoulder: 50,
    neutralAAShoulder: 0,

    ReShoulder: 80,
    RiShoulder: 70,
    NeutralRotationShoulder: 0,
  };

  const [TNVbasic, setTNVbasic] = useState({
    "absence de trouble neuro-vasculaire": false,
  });

  const handleCheckboxChangeTNVbasic = (event) => {
    const { name, checked } = event.target;
     
      setTNVbasic(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Examen clinique épaule");

     // Function to convert the state of the checkboxes into a readable string
     const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const TNVbasicText= formatCheckboxStateToString(TNVbasic);
 
    // Prepare form details for summary
    const formDetails = {
      "Examen clinique épaule": formState.side,
      "Etat cutané": [ formState.skin, formState.scar]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),
      "A l'inspection": formState.Amyotrophy,
      "Status N-V": TNVbasicText,

      
      "Flexion/Extension": [formState.AnteFlexionShoulder, formState.neutralEShoulder, formState.RetroflexionShoulder]
        .filter(val => val !== "" && val !== null && val !== undefined)
        .join('/'),
      "Abduction/Adduction": [formState.abdShoulder, formState.neutralAAShoulder, formState.addShoulder]
        .filter(val => val !== "" && val !== null && val !== undefined)
        .join('/'),
      "Rotation externe/interne": [formState.ReShoulder, formState.NeutralRotationShoulder, formState.RiShoulder]
        .filter(val => val !== "" && val !== null && val !== undefined)
        .join('/'),
      
      "Tests coiffe":[formState.JobeTest, formState.PalmTest, formState.PatteTest, formState.LiftOffTest, formState.BellyPress]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),

      "Impingement test": formState.ImpingementTest,
      "Apprehension test": formState.ApprehensionTest
    };


    onFormSubmit(formDetails); // Call the function passed from App.js and pass the details


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object


    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details}


}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSetDefaultValues = () => {
    setFormState((prevState) => ({
      ...prevState,
      ...defaultValues
    }));
  };

  return (
    <div className="card">
      <p className="title">Examen clinique épaule</p>
      <form className="specific-form" onSubmit={handleSubmit}>
      <div>
        <label>Côté:  </label>
        <input type="radio" name="side" value="gauche" checked={formState.side === "gauche"} onChange={handleInputChange} />
        <label> gauche </label>
      
        <input type="radio" name="side" value="droite" checked={formState.side === "droite"} onChange={handleInputChange} />
        <label> droit </label>
      </div>

    


    <label className="title-label-within-card">Etat cutané:</label>

      <div> 
      
        <select name="skin" className="select-input" value={formState.skin} onChange={handleInputChange}>
          <option value="pas de plaie, pas de cicatrice">Pas de plaie, pas de cicatrice</option>
          <option value="cicatrice d'abord arthroscopique">Cicactrice d'abord arthroscopique</option>
          <option value="cicatrice d'abord antérieur">Cicactrice d'abord antérieur</option>
          <option value="cicatrice d'abord latéral">cicatrice d'abord latéral</option>
          <option value="cicatrice d'abord postérieur">cicatrice d'abord postérieur</option>
          <option value="AUTRE">AUTRE</option>
        </select>

      

      {formState.skin !== "pas de plaie, pas de cicatrice" && (
           
              <select name="scar" className="select-input" value={formState.scar} onChange={handleInputChange} >
          <option value="calme, sans signe infectieux">calme, sans signe infectieux</option>
          <option value="inflammatoire">inflammatoire</option>
          <option value="avec signes infectieux (écoulement, rougeur)">signes infectieux (écoulement, rougeur)</option>
          <option value="chéloïde">chéloïde</option>
          <option value="AUTRE">AUTRE</option>
        </select>
         
         
      )
      
      }

</div>

<div> 
      
      <select name="Amyotrophy" className="select-input" value={formState.Amyotrophy} onChange={handleInputChange}>
      <option value="">Amyotrophie:</option>
        <option value="pas d'amyotrophie">pas d'amyotrophie</option>
        <option value="amyotrophie fosse supra-épineuse">amyotrophie fosse supra-épineuse</option>
        <option value="amyotrophie fosse infra-épineuse">amyotrophie fosse infra-épineuse</option>
        <option value="amyotrophie généralisée par rapport au controlatéral">amyotrophie généralisée par rapport au controlatéral</option>
        <option value="AUTRE">AUTRE</option>
      </select>

    

    </div>




<div className="checkbox-options">
        
            <input type="checkbox" name="absence de trouble neuro-vasculaire"  onChange={handleCheckboxChangeTNVbasic} />
            <label>absence de trouble neuro-vasculaire</label>
          
        
      </div>
  

<br></br>
  <label className="title-label-within-card">Amplitudes articulaire:</label>
  

  <div>
  <br></br>
   <button type="button" className="button-default-values" onClick={handleSetDefaultValues}>Partir des valeurs par défaut </button>
    
      <div>
      <br></br>
        <label>AntéF. - Neutre - Extension:</label>
        <input type="number" className="number-input" name="AnteFlexionShoulder"  placeholder = "120" value={formState.AnteFlexionShoulder} onChange={handleInputChange} />

        <input type="number" className="number-input" name="neutralEShoulder"  placeholder = "0" alue={formState.neutralEShoulder} onChange={handleInputChange} />
        
        <input type="number" className="number-input" name="RetroflexionShoulder"  placeholder = "20" value={formState.RetroflexionShoulder} onChange={handleInputChange} />
      </div>
      </div>


     
      <div>
      
        <label>Abduction - Neutre - Adduction:</label>
        <input type="number" className="number-input" name="abdShoulder"  placeholder = "45" value={formState.abdShoulder} onChange={handleInputChange} />
    
        <input type="number" className="number-input" name="neutralAAShoulder"  placeholder = "0" value={formState.neutralAAShoulder} onChange={handleInputChange} />
  
        <input type="number" className="number-input" name="addShoulder"  placeholder = "20" value={formState.addShoulder} onChange={handleInputChange} />
      </div>


      <div>
        <label>Rot. externe - Neutre - Rot. interne:</label>
        <input type="number" className="number-input" name="ReShoulder"  placeholder = "45" value={formState.ReShoulder} onChange={handleInputChange} />
      
        <input type="number" className="number-input" name="NeutralRotationShoulder"  placeholder = "0" value={formState.NeutralRotationShoulder} onChange={handleInputChange} />

        <input type="number" className="number-input" name="RiShoulder"  placeholder = "30" value={formState.RiShoulder} onChange={handleInputChange} />
      </div>

     
      <div> 
      <label className="title-label-within-card">Coiffe:</label>
      
        <select name="JobeTest" className="select-input" value={formState.JobeTest} onChange={handleInputChange}>
          <option value="">Jobe:</option>
          <option value="Jobe négatif">négatif</option>
          <option value="Jobe positif">positif</option>
          <option value="Jobe non testable">non testable</option>
          <option value="Jobe: AUTRE">AUTRE</option>
        </select>

        <select name="PalmTest" className="select-input" value={formState.PalmTest} onChange={handleInputChange}>
          <option value="">Palm up:</option>
          <option value="Palm up négatif">négatif</option>
          <option value="Palm up positif">positif</option>
          <option value="Palm up non testable">non testable</option>
          <option value="Palm up: AUTRE">AUTRE</option>
        </select>

        <select name="PatteTest" className="select-input" value={formState.PatteTest} onChange={handleInputChange}>
          <option value="">Patte:</option>
          <option value="Patte négatif">négatif</option>
          <option value="Patte positif">positif</option>
          <option value="Patte non testable">non testable</option>
          <option value="Patte: AUTRE">AUTRE</option>
        </select>

        <select name="LiftOffTest" className="select-input" value={formState.LiftOffTest} onChange={handleInputChange}>
          <option value="">Lift Off:</option>
          <option value="Lift Off négatif">négatif</option>
          <option value="Lift Off positif">positif</option>
          <option value="Lift Off non testable">non testable</option>
          <option value="Lift Off: AUTRE">AUTRE</option>
        </select>

        <select name="BellyPress" className="select-input" value={formState.BellyPress} onChange={handleInputChange}>
          <option value="">Belly Press:</option>
          <option value="Belly Press négatif">négatif</option>
          <option value="Belly Press positif">positif</option>
          <option value="Belly Press non testable">non testable</option>
          <option value="Belly Press: AUTRE">AUTRE</option>
        </select>

      </div>

      <div>
      <select name="ImpingementTest" className="select-input" value={formState.ImpingementTest} onChange={handleInputChange}>
          <option value="">Impingement test:</option>
          <option value="négatif">négatif</option>
          <option value="positif">positif</option>
          <option value="non testable">non testable</option>
          <option value="AUTRE">AUTRE</option>
        </select>

      </div>

      <div>
      <select name="ApprehensionTest" className="select-input" value={formState.ApprehensionTest} onChange={handleInputChange}>
          <option value="">Apprehension test:</option>
          <option value="négatif">négatif</option>
          <option value="positif">positif</option>
          <option value="non testable">non testable</option>
          <option value="AUTRE">AUTRE</option>
        </select>

      </div>




        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default Shoulder;