import React, { useState } from "react";
import "../../../styles/app.css";
import Modal from "../../../other-components/Modal.js";

function FemurXRayTraumat({setNameForm, onFormSubmit}) {


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [specificImage, setSpecificImage] = useState("");

  const openModalWithSpecificImage = (image) => {
    setSpecificImage(image);
    setIsModalOpen(true);
  }

  const [formState, setFormState] = useState({
    xRayWorkUp: '',
    sideProxFemur: '',
    femoralNeckFracture: '',
    trochantericFracture: '',
    subTrochantericFracture:'',
    femoralDiaphysisFracture:'',
    distalFemurFracture:''
  });

  const [ProxFemurXRayTraumat, setProxFemurXRayTraumat] = useState({
    "Bassin de face":false,
    "Bassin calibré centré symphyse":false,
    "Hanche face, gauche":false,
    "Hanche axiale, gauche": false,
    "Hanche face, droite":false,
    "Hanche axiale, droite": false,
    "Fémur face, gauche": false,
    "Fémur profil, gauche": false,
    "Fémur face, droite": false,
    "Fémur profil, droite": false,
    "CT-scan bassin": false,
    "CT-scan hanche": false,
  
  });

  const [showXray, setshowXray] = useState(false);

  const handleChangeshowXray = (event) => {
    setshowXray(event.target.checked);
  }



  const handleCheckboxChangeProxFemurXRayTraumat = (event) => {
    const { name, checked } = event.target;
     
      setProxFemurXRayTraumat(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Fémur, traumatologie");
 // Function to convert the state of the checkboxes into a readable string
    const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const ProxFemurXRayTraumatText = formatCheckboxStateToString(ProxFemurXRayTraumat);


    // Prepare form details for summary
    const formDetails = {
      "Bilan radiologique": ProxFemurXRayTraumatText,
      "Du côté": formState.sideProxFemur,
      "Fracture col fémoral": formState.femoralNeckFracture,
      "Fracture massif trochantérien": formState.trochantericFracture,
      "Fracture sous-trochantérienne": formState.subTrochantericFracture,
      "Fracture diaphyse fémorale": formState.femoralDiaphysisFracture,
      "Fracture fémur distal": formState.distalFemurFracture
      
    };


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

   


    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };






  return (
    <div className="card">
      <p className="title">Fémur, bilan radiologique</p>
      <form className="specific-form" onSubmit={handleSubmit}>


      <div>
   
   <input
       type="checkbox"
       name="showXray"
       onChange={handleChangeshowXray}
       
     />
     <label>Renseigner incidences radiologiques</label>

   </div>
   

   {showXray && (
       <div className="checkbox-options">
       
       {Object.entries(ProxFemurXRayTraumat).map(([key, value]) => (
         <div key={key}>
           <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeProxFemurXRayTraumat} />
           <label>{key}</label>
         </div>
       ))}
     </div>


   )}
 
   

      <br></br>


        <div className="checkbox-options">
          <p>Côté lésion:</p>
            <div>
             <input type="radio" name="sideProxFemur" value="gauche" checked={formState.sideProxFemur === "gauche"} onChange={handleInputChange} />
            <label> gauche </label>
         
            <input type="radio" name="sideProxFemur" value="droit" checked={formState.sideProxFemur === "droit"} onChange={handleInputChange} />
            <label> droit </label>
            </div>
        </div>

        <div>
        
          <select name="femoralNeckFracture" className="select-input" value={formState.femoralNeckFracture} onChange={handleInputChange} >
            <option value="">Fracture du col fémoral:</option>
            <option value="impactée en valgus, type I selon Garden">impactée en valgus, type I selon Garden</option>
            <option value="in situ, type II selon Garden">in situ, type II selon Garden</option>
            <option value="complète partiellement déplacée, type III selon Garden">complète partiellement déplacée, type III selon Garden</option>
            <option value="complète et déplacée, type IV selon Garden">complète et déplacée, type IV selon Garden</option>
            <option value="">----------</option>
            <option value="basi-cervicale">basi-cervicale</option>
            <option value="">----------</option>
            <option value="type I selon Pauwels (<30 de l'horizontale)">type I selon Pauwels ({'<'}30 de l'horizontale)</option>
            <option value="type II selon Pauwels (entre 30 et 50 de l'horizontale)">type II selon Pauwels (entre 30 et 50 de l'horizontale)</option>
            <option value="type III selon Pauwels (>50 de l'horizontale)">type III selon Pauwels ({'>'}50 de l'horizontale)</option>



          </select>
        </div>




        <div>
    
          <select name="trochantericFracture" className="select-input" value={formState.trochantericFracture} onChange={handleInputChange} >
            <option value="">Fracture massif trochantérien:</option>
            <option value="type I selon Kyle">pertrochantérienne, type I selon Kyle</option>
            <option value="avec fracture petit trochanter, type II selon Kyle"> pertrochantérienne avec fracture petit trochanter, type II selon Kyle</option>
            <option value="avec fractures petit et grand trochanters, type III selon Kyle">pertrochantérienne avec fractures petit et grand trochanters, type III selon Kyle</option>
            <option value="comminutive, extension petit et grand trochanters, type IV selon Kyle">pertrochantérienne comminutive, extension petit et grand trochanters, type IV selon Kyle</option>
            </select>
            </div>


            

            <div>
          <select name="subTrochantericFracture" className="select-input" value={formState.subTrochantericFracture} onChange={handleInputChange} >
            <option value="">Fracture sous-trochantérique:</option>
            <option value="Seinsheimer type 1">Seinsheimer type 1</option>
            <option value="Seinsheimer type 2a">Seinsheimer type 2a</option>
            <option value="Seinsheimer type 2b">Seinsheimer type 2b</option>
            <option value="Seinsheimer type 2c">Seinsheimer type 2c</option>
            <option value="Seinsheimer type 3a">Seinsheimer type 3a</option>
            <option value="Seinsheimer type 3b">Seinsheimer type 3b</option>
            <option value="Seinsheimer type 4">Seinsheimer type 4</option>
            <option value="Seinsheimer type 5">Seinsheimer type 5</option>

            </select>

        </div>


        

        <div>
      
          <select name="femoralDiaphysisFracture" className="select-input" value={formState.femoralDiaphysisFracture} onChange={handleInputChange} >
            <option value="">Fracture diaphyse fémorale:</option>
            <option value="spiroide (Classification AO 32-A1)">spiroide (Classification AO 32-A1)</option>
            <option value="oblique (Classification AO 32-A2)">oblique (Classification AO 32-A2)</option>
            <option value="transverse (Classification AO 32-A3)">transverse (Classification AO 32-A3)</option>
            <option value="type wedge (Classification AO 32-B2)">type wedge (Classification AO 32-B2)</option>
            <option value="type wedge, fragmentaire (Classification AO 32-B3)">type wedge, fragmentaire (Classification AO 32-B3)</option>
            <option value="segmentaire (Classification AO 32-C2)">segmentaire (Classification AO 32-C2)</option>
            <option value="segmentaire, plurifragmentaire (Classification AO 32-C3)">segmentaire, plurifragmentaire (Classification AO 32-C3)</option>
         
            </select>
            </div>

          <div>

          
                      <select name="distalFemurFracture" className="select-input" value={formState.distalFemurFracture} onChange={handleInputChange} >
                        <option value="">Fracture fémur distal:</option>
                        <option value="extra-articulaire (Classification AO 33-A)">extra-articulaire (Classification AO 33-A)</option>
                        <option value="articulaire partielle, atteinte latérale (Classification AO 33-B1)">articulaire partielle, atteinte latérale (Classification AO 33-B1)</option>
                        <option value="articulaire partielle, atteinte médiale (Classification AO 33-B2)">articulaire partielle, atteinte médiale (Classification AO 33-B2)</option>
                        <option value="articulaire partielle, fracture coronale (fracture de Hoffa / AO 33-B3)">articulaire partielle, fracture coronale (fracture de Hoffa / AO 33-B3)</option>
                        <option value="intra-articulaire (Classification AO 33-C)">intra-articulaire (Classification AO 33-C)</option>
                
                      </select>

            </div>  
          
      
        
        <button type="submit" className="button send">Send</button>
      </form>


      <br></br>

     
     


      <button className="button modal-trigger" onClick={() => openModalWithSpecificImage("./Illustrations/FemoralNeckFractures.png")}>Help: Fracture col fémoral</button>
     

   

      {isModalOpen && ( /* If isModalOpen is true, <Modal> will be displayed. */
        <Modal imageSrc={specificImage} onClose={() => setIsModalOpen(false)} />
      )}
    </div>
  );
}

export default FemurXRayTraumat;