import React, { useEffect, useState } from "react";
import "../../../styles/app.css";
import Modal from "../../../other-components/Modal.js";

function HipXray({setNameForm, onFormSubmit}) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [specificImage, setSpecificImage] = useState("");

  const [formState, setFormState] = useState({
    sideHip: '',
    cervicoDiaphysealAngle: '',
    lateralCoverageAngle:'',
    TonnisAngle:'',
    OA_Tonnis:'',
    OA_KellgrenLawrence:'',
    AVN:''

  });

  const [HipXray, setHipXray] = useState({
    "Bassin de face":false,
    "Bassin calibré centré symphyse":false,
    "Lequesne": false,
    "Lauenstein": false,
    "Hanche face" :false,
    "Hanche axiale": false,
    "CT-scan bassin": false,
    "CT-scan hanche": false,
  
  });

  const openModalWithSpecificImage = (image) => {
    setSpecificImage(image);
    setIsModalOpen(true);
  }

  const [showXray, setshowXray] = useState(false);

  const handleChangeshowXray = (event) => {
    setshowXray(event.target.checked);
  }



  const handleCheckboxChangeHipXray = (event) => {
    const { name, checked } = event.target;
     
      setHipXray(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };

  const [commentCCAngle, setcommentCCAngle] = useState(null)

  useEffect(() => {
    if(formState.cervicoDiaphysealAngle){
      if (formState.cervicoDiaphysealAngle < 125)
        setcommentCCAngle("(Coxa Vara, norme 125-135°)")
      if (formState.cervicoDiaphysealAngle > 120 && formState.cervicoDiaphysealAngle < 135)
        setcommentCCAngle("(Dans la norme, N = 125-135°)")
      if (formState.cervicoDiaphysealAngle > 135)
        setcommentCCAngle("(Coxa Valga, norme 125-135°)")
    }
    else {setcommentCCAngle(null)}
  
    
      }, [formState.cervicoDiaphysealAngle])




   const [commentLCA, setcommentLCA] = useState(null)

  useEffect(() => {
    if (formState.lateralCoverageAngle){
      if (formState.lateralCoverageAngle < 20)
        setcommentLCA("(versant dysplasique, norme 20-40°)")
      if (formState.lateralCoverageAngle > 20 && formState.lateralCoverageAngle < 40)
        setcommentLCA("(Dans la norme, N = 20-40°)")
      if (formState.lateralCoverageAngle > 40)
        setcommentLCA("(Surcouverture, norme 20-40°)")
}
else {setcommentLCA(null)}

 }, [formState.lateralCoverageAngle])

 const [commentTonnis, setcommentTonnis] = useState(null)

 useEffect(() => {
   if (formState.TonnisAngle){
     if (formState.TonnisAngle < 0)
       setcommentTonnis("(surcouverture; norme 0-10°)")
     if (formState.TonnisAngle > 0 && formState.TonnisAngle < 10)
       setcommentTonnis("(Dans la norme, N = 0-10°)")
     if (formState.TonnisAngle > 10)
       setcommentTonnis("(Versant dysplasique; norme 0-10°)")
}
else {setcommentTonnis(null)}

}, [formState.TonnisAngle])


      
      




  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Hanche native");
 // Function to convert the state of the checkboxes into a readable string
    const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const HipXrayText = formatCheckboxStateToString(HipXray);

  
  






  


    // Prepare form details for summary
    const formDetails = {
      "hanche": formState.sideHip,
      "Bilan radiologique": HipXrayText,
      "Angle cervico-diaphysaire": [formState.cervicoDiaphysealAngle, commentCCAngle].filter(function(val) {
        return val;
      }).join(' '),

      "Angle de couverture latérale": [formState.lateralCoverageAngle, commentLCA].filter(function(val) {
        return val;
      }).join(' '),


      "Angle de Tönnis": [formState.TonnisAngle, commentTonnis].filter(function(val) {
        return val;
      }).join(' '),

      "Trouble dégéneratif selon Tönnis": formState.OA_Tonnis,
      "Trouble dégéneratif selon Kellgren & Lawrence": formState.OA_KellgrenLawrence,
      "Signe de nécrose aseptique tête fémorale": formState.AVN,
      
    };


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

   


    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };






  return (
    <div className="card">
      <p className="title">Hanche native, bilan radiologique</p>
      <form className="specific-form" onSubmit={handleSubmit}>

      <div className="checkbox-options">
    
          <div>
          <input type="radio" name="sideHip" value="gauche" checked={formState.sideHip === "gauche"} onChange={handleInputChange} />
          <label> gauche </label>
      
          <input type="radio" name="sideHip" value="droit" checked={formState.sideHip === "droit"} onChange={handleInputChange} />
          <label> droite </label>
          </div>
      </div>

      



        <div>
   
   <input
       type="checkbox"
       name="showXray"
       onChange={handleChangeshowXray}
       
     />
     <label>Renseigner incidences radiologiques</label>

   </div>
   

   {showXray && (
      <div className="checkbox-options">
      <p className="description">Incidence radiologiques:</p>
      {Object.entries(HipXray).map(([key, value]) => (
        <div key={key}>
          <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeHipXray} />
          <label>{key}</label>
        </div>
      ))}
    </div>
     


   )}
 
    

      <br></br>
  

      
      <label> Analyse angulaire: </label>
            <div>
          <input type="number" className="number-input" name="cervicoDiaphysealAngle" min="0" max="180" placeholder = "C-D" value={formState.cervicoDiaphysealAngle} onChange={handleInputChange} />
          <input type="number" className="number-input" name="lateralCoverageAngle" min="0" max="180" placeholder = "LCA" value={formState.lateralCoverageAngle} onChange={handleInputChange} />
          <input type="number" className="number-input" name="TonnisAngle" min="0" max="180" placeholder = "Tönnis" value={formState.TonnisAngle} onChange={handleInputChange} />

          <label className = "indication-italic"> C-D: angle cervico-diaphysaire</label> <br></br>
          <label className = "indication-italic"> LCA: lateral coverage angle</label><br></br>
          <label className = "indication-italic"> Les normes correspondante apparaissent dans le rapport si les angles sont renseignés</label>
       
       </div>
      
       
  

    <div>
         
         <select name="OA_Tonnis" className="select-input" value={formState.OA_Tonnis} onChange={handleInputChange} >
           <option value="">Atteinte dégénerative selon Tönnis:</option>
           <option value="Stade 0; pas de signe d'arthrose">Stade 0; pas de signe d'arthrose</option>
           <option value="Stade 1; sclérose sous-chondrale, léger pincement articulaire, absence ou légère perte de sphéricité de la tête fémorale.">Stade 1; sclérose sous-chondrale, léger pincement articulaire, absence ou légère perte de sphéricité tête fémorale</option>
           <option value="Stade 2; discrète géodes, pincement interligne modéré, perte modérée de sphéricité de la tête fémorale">Stade 2; discrète géodes, pincement interligne modéré, perte modérée de sphéricité tête fémorale</option>
           <option value="Stade 3; géodes marquées, pincement sévère ou complet de l’interligne, déformation sévère de la tête fémorale">Stade 3; géodes marquées, pincement sévère ou complet de l’interligne, déformation sévère de la tête fémorale</option>
         
         </select>

         <select name="OA_KellgrenLawrence" className="select-input" value={formState.OA_KellgrenLawrence} onChange={handleInputChange} >
           <option value="">Atteinte dégénerative selon Kellgren Lawrence:</option>
           <option value="Stade 0; pas de signe d'arthrose">Stade 0; pas de signe d'arthrose</option>
           <option value="Stade 1; minimes ostéophytes, pincement articulaire discret">Stade 1; minimes ostéophytes, pincement articulaire discret</option>
           <option value="Stade 2; discrète géodes, pincement interligne modéré, perte modérée de sphéricité de la tête fémorale">Stade 2; ostéphoytes ou pincement articulaire</option>
           <option value="Stade 3; pincement interligne et sclérose sous-chondrale">Stade 3; pincement interligne et sclérose sous-chondrale</option>
           <option value="Stade 4; pincement complet de l'interlgne articulaire">Stade 4; pincement complet de l'interlgne articulaire</option>
         </select>

         <select name="AVN" className="select-input" value={formState.AVN} onChange={handleInputChange} >
           <option value="">Signe de nécrose tête fémorale:</option>
           <option value="pas de signe de nécrose aseptique">aucun</option>
           <option value="ostéopénie tête fémorale">ostéopénie tête fémorale</option>
           <option value="Crescent sign">Crescent sign</option>
           <option value="Crescent sign et perte de sphéricité">Crescent sign et perte de sphéricité</option>
           <option value="Perte de sphericité et arthrose secondaire">Perte de sphericité et arthrose secondaire</option>
         </select>

       </div>



        
        <button type="submit" className="button send">Send</button>
      </form>

      <br></br>

     
     


      <button className="button modal-trigger" onClick={() => openModalWithSpecificImage("./Illustrations/AnglesHip.png")}>Help: Analyse angulaire</button>
     

   

      {isModalOpen && ( /* If isModalOpen is true, <Modal> will be displayed. */
        <Modal imageSrc={specificImage} onClose={() => setIsModalOpen(false)} />
      )}
      
      
    </div>
  );
}

export default HipXray;