import React from "react";
import "../styles/app.css";

function Summary({nameSpecificCard, setIsFormSended, formDetails}) {

    // Function to render the form details.
    const renderDetails = () => {

        if (!formDetails) return "No form details available.";

        return Object.entries(formDetails).map(([question, answer], index) => (
            <p key={index}>
                 <span className="question"> {question}:</span>
                <span className="answer"> {answer.toString()}</span>
            </p>
        ));
      };

    return(

        <>
            <div className="modal-summary">
                <div className="modal-confirmation-summary">
                    <h3 className="confirmation-title-summary">{nameSpecificCard}</h3>
                    <div className="confirmation-message-summary">
                        {renderDetails()}
                    </div>
                    <div className="confirmation-buttons-summary">
                        <button className="button-summary" onClick={() => setIsFormSended(false)}>Close</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Summary;