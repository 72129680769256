// Allergies.js
import React, { useState } from 'react';
import '../../../styles/app.css';

function MedicalTreatment({ setNameForm, onFormSubmit }) {
  const [formState, setFormState] = useState({
    Paracetamol:'',
    CodeineParacetamol: '', 
    Tramadol: '',


    AINS_1_arylacetiques: '',
    AINS_2_arylpropionique:'',
    AINS_coxibs:'',

    AI_corticoides:'',

    AntiCoag_acenocoumarol:'',
    AntiCoag_Heparins:'',
    Anticoag_Direct_Oral:'',
    
  });



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    setNameForm('Traitement en cours');


    const formDetails = {
      'Paracetamol': formState.Paracetamol,
      'CodeineParacetamol': formState.CodeineParacetamol,
      'Tramadol': formState.Tramadol,
      'AINS_1_arylacetiques': formState.AINS_1_arylacetiques,
      'AINS_2_arylpropionique': formState.AINS_2_arylpropionique,
      'AINS_coxibs': formState.AINS_coxibs,
      'AI_corticoides': formState.AI_corticoides,
    
      'AntiCoag_acenocoumarol': formState.AntiCoag_acenocoumarol,
      'AntiCoag_Heparins': formState.AntiCoag_Heparins,
      'Anticoag_Direct_Oral': formState.Anticoag_Direct_Oral
     
      
    };

    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  return (
    <div className="card">
      <p className="title">Traitement en cours</p>
      <form className="specific-form" onSubmit={handleSubmit}>

      <label className = "title-label-within-card" > Antalgiques: </label>

        <div>
          <select name="Paracetamol" className="select-input" value={formState.Paracetamol} onChange={handleInputChange}>
            <option value="">Paracetamol:</option>
            <option value="Paracetamol, posologie non spécifiée">Paracetamol, posologie non spécifiée</option>
            <option value="Paracetamol, 1gr 4x/J en réserve">Paracetamol, 1gr 4x/J en réserve</option>
            <option value="Paracetamol, 1gr 4x/J d'office">Paracetamol, 1gr 4x/J d'office</option>
          </select>
        
          <select name="CodeineParacetamol" className="select-input" value={formState.CodeineParacetamol} onChange={handleInputChange}>
            <option value="">Codeine + Paracetamol:</option>
            <option value="Co-Dafalgan (30mg codéine, 500mg Paracetamol, posologie non spécifiée">Co-Dafalgan {'('}30mg codéine, 500mg Paracetamol{')'}, posologie non spécifiée</option>
            <option value="Co-Dafalgan (30mg codéine, 500mg Paracetamol) 4x/J en réserve">Co-Dafalgan {'('}30mg codéine, 500mg Paracetamol{')'} 4x/J en réserve</option>
            <option value="Co-Dafalgan (30mg codéine, 500mg Paracetamol) 4x/J d'office">Co-Dafalgan {'('}30mg codéine, 500mg Paracetamol{')'} 4x/J d'office</option>
        
          </select>

          <select name="Tramadol" className="select-input" value={formState.Tramadol} onChange={handleInputChange}>
            <option value="">Tramal:</option>
            <option value="Co-Dafalgan (30mg codéine, 500mg Paracetamol, posologie non spécifiée">Tramadol 25mg, posologie non spécifiée</option>
            <option value="Tramadol 25mg, 4x/J en réserve">Tramadol 25mg, 4x/J en réserve</option>
            <option value="Tramadol 25mg, 4x/J d'office">Tramadol 25mg, 4x/J d'office</option>
            <option value="Tramadol 50mg, 4x/J en réserve">Tramadol 50mg, 4x/J en réserve</option>
            <option value="Tramadol 50mg, 4x/J d'office">Tramadol 50mg, 4x/J d'office</option>
        
          </select>

          <select name="AINS_1_arylacetiques" className="select-input" value={formState.AINS_1_arylacetiques} onChange={handleInputChange}>
            <option value="">AINS, dérivés arylacétiques:</option>
            <option value="Diclofénac">Diclofenac, poso et nom comm à compléter</option>
    
            
          </select>

          <select name="AINS_2_arylpropionique" className="select-input" value={formState.AINS_2_arylpropionique} onChange={handleInputChange}>
            <option value="">AINS, -profènes:</option>
            <option value="Ibuprofène 400mg, 3x/J en réserve">Ibuprofène 400mg, 3x/J en réserve</option>
            <option value="Ibuprofène 400mg, 3x/J d'office">Ibuprofène 400mg, 3x/J d'office</option>
          
          </select>

          
          <select name="AINS_coxibs" className="select-input" value={formState.AINS_coxibs} onChange={handleInputChange}>
            <option value="">Coxibs:</option>
            <option value="Célécoxib, poso à compléter">Célécoxib, poso et nom comm à compléter</option>
        
          
          </select>

        </div>

        <div>
        <select name="AI_corticoides" className="select-input" value={formState.AI_corticoides} onChange={handleInputChange}>
            <option value="">Anti-inflammatoire stéroidien:</option>
            <option value="options à compléter">options à compléter</option>
        
          
          </select>
        </div>

  
      

        <label className = "title-label-within-card" > Anticoagulant: </label>



        <div>
          <select name="AntiCoag_acenocoumarol" className="select-input" value={formState.AntiCoag_acenocoumarol} onChange={handleInputChange}>
            <option value="">AVK:</option>
            <option value="acénocoumarol (Sintrom)">acénocoumarol {'('}Sintrom{')'}</option>
            <option value="warfarine (Coumadine)">warfarine {'('}Coumadine{')'}</option>
        
          </select>
       
          <select name="AntiCoag_Heparins" className="select-input" value={formState.AntiCoag_Heparins} onChange={handleInputChange}>
            <option value="">Héparines:</option>
            <option value="HBPM, Enoxaparine">HBPM, Enoxaparine </option>
            <option value="HBPM, Fondaparinux">HBPM, Fondaparinux</option>
            <option value="HNF, calciparine">HNF, calciparine</option>
            <option value="HNF, héparine">HNF, héparine</option>
         
        
          </select>

          <select name="AntiCoag_Heparins" className="select-input" value={formState.AntiCoag_Heparins} onChange={handleInputChange}>
            <option value="">Anti-coagulants oraux:</option>
            <option value="HBPM, Enoxaparine">Dabigatran </option>
            <option value="HBPM, Fondaparinux">Apixaban</option>
            <option value="HNF, calciparine">Edoxaban</option>
            <option value="HNF, héparine">Rivaroxaban</option>
            <option value="HNF, héparine">nom comm et posologie à compléter</option>
         
        
          </select>
        </div>
        
       

        


        

        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default MedicalTreatment;