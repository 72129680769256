import React, { useState } from "react";
import "../../../styles/app.css";

function Elbow({setNameForm, onFormSubmit}) { 
  const [formState, setFormState] = useState({
    Side: "",
    Skin: "pas de plaie, pas de cicatrice",
    Scar:"",
    Effusion: "",

    Flexion:"",
    NeutralFE:"",
    Extension:"",

    Pronation:"",
    Supination:"",
    NeutralPS:"",

    MedialStability: "",
    LateralStability: "",
    LateralPivotShiftTest:""
    
  });

  const defaultValues = {
    Flexion: 135,
    NeutralFE: 0,
    Extension: 0,
    Pronation: 80,
    Supination: 80,
    NeutralPS: 0,
  };


  const [elbowPalpation, setelbowPalpation] = useState({
    "épicondyle médial":false,
    "épicondyle latéral":false,
    "tête radiale": false,
    "insertion ECRB/ECRL": false,
    "AUTRE": false
  
  });

  const [TNVbasic, setTNVbasic] = useState({
    "absence de trouble neuro-vasculaire": false,
  });


  const handleCheckboxChangeElbowPalpation = (event) => {
    const { name, checked } = event.target;
     
      setelbowPalpation(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };
  const handleCheckboxChangeTNVbasic = (event) => {
    const { name, checked } = event.target;
     
      setTNVbasic(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Examen clinique coude");
 // Function to convert the state of the checkboxes into a readable string
    const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const elbowPalpationText = formatCheckboxStateToString(elbowPalpation);
  const TNVbasicText= formatCheckboxStateToString(TNVbasic);


    // Prepare form details for summary
    const formDetails = {
      "Examen clinique coude": formState.Side,

      "Etat cutané": [ formState.Skin, formState.Scar]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),

      "Status N-V": TNVbasicText,
      "Fluctuation bourse olécranienne": formState.Effusion,
      
      "Site(s) douloureux à la palpation": elbowPalpationText,

      "Flexion/Extension": [formState.Flexion, formState.NeutralFE, formState.Extension]
        .filter((val) => val !== "" && val !== null && val !== undefined)
        .join('/'),

      "Prono-supination": [formState.Pronation, formState.NeutralPS, formState.Supination]
      .filter((val) => val !== "" && val !== null && val !== undefined)
      .join('/'),
    

      "Stabilité médiale": formState.MedialStability,
      "Stabilité latérale": formState.LateralStability,
      "Lateral Pivot Shift test": formState.LateralPivotShiftTest
      
      
    };

    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSetDefaultValues = () => {
    setFormState((prevState) => ({
      ...prevState,
      ...defaultValues
    }));
  };

  return (
    <div className="card">
      <p className="title">Examen clinique coude</p>
      <form className="specific-form" onSubmit={handleSubmit}>
      <div>
          <label>Côté:  </label>
          <input type="radio" name="Side" value="gauche" checked={formState.Side === "gauche"} onChange={handleInputChange} />
            <label> gauche </label>
         
            <input type="radio" name="Side" value="droit" checked={formState.Side === "droit"} onChange={handleInputChange} />
            <label> droit </label>
        </div>

        <div> 

        <label>Etat cutané:</label>
          <select name="Skin" className="select-input" value={formState.Skin} onChange={handleInputChange} >
            <option value="pas de plaie, pas de cicatrice">pas de plaie, pas de cicatrice</option>
            <option value="cicatrices d'abord arthroscopique">Cicactrice d'abord arthroscopique</option>
            <option value="cicatrice d'abord médian">cicatrice d'abord médian</option>
            <option value="cicatrice d'abord antéro-médial">cicatrice d'abord antéro-médial</option>
            <option value="cicatrice d'abord antéro-latéral">cicatrice d'abord antéro-latéral</option>
            <option value="AUTRE">AUTRE</option>
          </select>

          {formState.Skin !== "pas de plaie, pas de cicatrice" && (
           <div>
              <select name="Scar" className="select-input" value={formState.Scar} onChange={handleInputChange} >
          <option value="calme, sans signe infectieux">calme, sans signe infectieux</option>
          <option value="inflammatoire">inflammatoire</option>
          <option value="avec signes infectieux (écoulement, rougeur)">signes infectieux (écoulement, rougeur)</option>
          <option value="chéloïde">chéloïde</option>
          <option value="AUTRE">AUTRE</option>
        </select>
         
         </div>
      )
    }
        
        </div>

       

      <div className="checkbox-options">
        
            <input type="checkbox" name="absence de trouble neuro-vasculaire"  onChange={handleCheckboxChangeTNVbasic} />
            <label>absence de trouble neuro-vasculaire</label>
          
      </div>
  
      

        <div className="checkbox-options">
        <p className="title-label-within-card">Douleurs à la palpation:</p>
        {Object.entries(elbowPalpation).map(([key, value]) => (
          <div key={key}>
            <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeElbowPalpation} />
            <label>{key}</label>
          </div>
        ))}
      </div>


      <div> 
          <p>Fluctuation bourse olécranienne: </p>
          <div>
            <input type="radio" name="Effusion" value="absente" checked={formState.Effusion === "absente"} onChange={handleInputChange} />
            <label> - </label>
          
            <input type="radio" name="Effusion" value="présente" checked={formState.Effusion === "présente"} onChange={handleInputChange}/>
            <label> + </label>
          </div>
        </div>
      

      <div className="radio-options">
         
       <div>
        
        <label className="title-label-within-card">Amplitudes articulaire: <br></br></label>
          <br></br>
          <div>
           <button type="button" className="button-default-values" onClick={handleSetDefaultValues}>Partir des valeurs par défaut </button>
           </div>
           
        </div> 

        <div>
          <label>Flexion - Position neutre - Extension:</label>
          <input type="number" className="number-input" name="Flexion" min="0" max="150" placeholder="135" value={formState.Flexion} onChange={handleInputChange} />
          <input type="number" className="number-input" name="NeutralFE" min="0" max="150" placeholder="0" value={formState.NeutralFE} onChange={handleInputChange} />
          <input type="number" className="number-input" name="Extension" min="-30" max="150" placeholder="0" value={formState.Extension} onChange={handleInputChange} />
        </div>

        <div>
          <label>Pronation - Position neutre - Supination:</label>
          <input type="number" className="number-input" name="Pronation" min="0" max="150" placeholder="135" value={formState.Pronation} onChange={handleInputChange} />
          <input type="number" className="number-input" name="NeutralPS" min="0" max="150" placeholder="0" value={formState.NeutralPS} onChange={handleInputChange} />
          <input type="number" className="number-input" name="Supinationn" min="-30" max="150" placeholder="0" value={formState.Supination} onChange={handleInputChange} />
        </div>


      
  
          <select name="MedialStability" className="select-input" value={formState.MedialStability} onChange={handleInputChange} >
            <option value="">Stabilité médiale:</option>
            <option value="stable">stable</option>
            <option value="instable">instable</option>
            <option value="non testable">non testable</option>
          </select>
       
        
          <select name="LateralStability" className="select-input" value={formState.LateralStability} onChange={handleInputChange}>
            <option value="">Stabilité latérale:</option>
            <option value="stable">stable</option>
            <option value="instable">instable</option>
            <option value="non testable">Non testable</option>
          </select>

          <select name="LateralPivotShiftTest" className="select-input" value={formState.LateralPivotShiftTest} onChange={handleInputChange}>
            <option value="">Lateral Pivot Shift test:</option>
            <option value="négatif">négatif</option>
            <option value="positif">positif</option>
            <option value="non testable">non testable</option>
          </select>
       
        
        </div>


        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default Elbow;