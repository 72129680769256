// Allergies.js
import React, { useEffect, useState } from 'react';
import '../../../styles/app.css';

function InstabilityScore({ setNameForm, onFormSubmit }) {
  const [formState, setFormState] = useState({
    Side:'',
    Q1_InstabilityScore: '',
    Q2_InstabilityScore: '',
    Q3_InstabilityScore: '',
    Q4_InstabilityScore: '',
    Q5_InstabilityScore: '',
    Q6_InstabilityScore: '',
  
  });

  



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const [InstabilityScore, setInstabilityScore] = useState(null);

  useEffect(() => {
    if (formState.Q1_InstabilityScore && formState.Q2_InstabilityScore &&
      formState.Q3_InstabilityScore && formState.Q4_InstabilityScore &&
      formState.Q5_InstabilityScore && formState.Q6_InstabilityScore 

    ) {
      
      var InstabilityScoreValue = 0;
     
      if (formState.Q1_InstabilityScore === "age <20 ans") {InstabilityScoreValue += 2;}
      
      if (formState.Q2_InstabilityScore === "sport de compétition") {InstabilityScoreValue += 2;}
    
     
      if (formState.Q3_InstabilityScore === "sport de contact ou forced overhead") {InstabilityScoreValue += 1;}
      
      if (formState.Q4_InstabilityScore === "hyperlaxité antérieure/inférieure") {InstabilityScoreValue += 1;}
   
      if (formState.Q5_InstabilityScore === "lésion Hill Sachs visible AP") {InstabilityScoreValue += 2;}
      
      if (formState.Q6_InstabilityScore === "perte de contour glénoidien AP") {InstabilityScoreValue += 2;}
      
     
      setInstabilityScore(InstabilityScoreValue);
     } 

     
     else {
       setInstabilityScore("");
     }
    }, [
  formState.Q1_InstabilityScore, formState.Q2_InstabilityScore,
  formState.Q3_InstabilityScore, formState.Q4_InstabilityScore,
  formState.Q5_InstabilityScore, formState.Q6_InstabilityScore]);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    setNameForm('Instability severity index score');


    const formDetails = {
    'ClinicalScore': "Instability severity index score, épaule " + formState.Side + ":" + InstabilityScore + "/10",

    
    'L1': "(" + [formState.Q1_InstabilityScore, formState.Q2_InstabilityScore,formState.Q3_InstabilityScore,formState.Q4_InstabilityScore,formState.Q5_InstabilityScore,formState.Q6_InstabilityScore]
    .filter(val => val !== "" && val !== null && val !== undefined)
    .join(', ')
    +")",

  
    
     
      
    };

    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  return (
    <div className="card">
      <p className="title">Instability severity index score</p>
      <form className="specific-form" onSubmit={handleSubmit}>


      <div className="checkbox-options">
    
        <div>
        <input type="radio" name="Side" value="gauche" checked={formState.Side === "gauche"} onChange={handleInputChange} />
        <label> épaule gauche </label>

        <input type="radio" name="Side" value="droite" checked={formState.Side === "droite"} onChange={handleInputChange} />
        <label> épaule droite </label>
        </div>
        </div>


      <br></br>

      

        <div className="checkbox-options">
    
        <label> Age:</label> <br></br>
        <div>
        <input type="radio" name="Q1_InstabilityScore" value="age <20 ans" checked={formState.Q1_InstabilityScore === "age <20 ans"} onChange={handleInputChange} />
        <label> {'<'} 20ans </label>

        <input type="radio" name="Q1_InstabilityScore" value="age >20 ans" checked={formState.Q1_InstabilityScore === "age >20 ans"} onChange={handleInputChange} />
        <label> {'>'} 20ans </label>
        </div>
        </div>

        <br></br>

        <div className="checkbox-options">
    
        <label> Niveau sport:</label> 
        
        <div>
          
        <input type="radio" name="Q2_InstabilityScore" value="sport de compétition" checked={formState.Q2_InstabilityScore === "sport de compétition"} onChange={handleInputChange} />
        <label> compétition </label>

        <input type="radio" name="Q2_InstabilityScore" value="sport récréatif ou aucun sport" checked={formState.Q2_InstabilityScore === "sport récréatif ou aucun sport"} onChange={handleInputChange} />
        <label> récréatif ou aucun </label>
        </div>
        </div>
        
        <br></br>

        <div className="checkbox-options">

        <label> Type de sport:</label> 

        <div>
          
        <input type="radio" name="Q3_InstabilityScore" value="sport de contact ou forced overhead" checked={formState.Q3_InstabilityScore === "sport de contact ou forced overhead"} onChange={handleInputChange} />
        <label> contact ou forced overhead </label>

        <input type="radio" name="Q3_InstabilityScore" value="pas de sport à risque" checked={formState.Q3_InstabilityScore === "pas de sport à risque"} onChange={handleInputChange} />
        <label> autre </label>
        </div>
        </div>

        <br></br>

        <div className="checkbox-options">

        <label> Hyperlaxité épaule:</label> 

        <div>
          
        <input type="radio" name="Q4_InstabilityScore" value="hyperlaxité antérieure/inférieure" checked={formState.Q4_InstabilityScore === "hyperlaxité antérieure/inférieure"} onChange={handleInputChange} />
        <label>antérieure/inférieure </label>

        <input type="radio" name="Q4_InstabilityScore" value="pas d'hyperlaxité" checked={formState.Q4_InstabilityScore === "pas d'hyperlaxité"} onChange={handleInputChange} />
        <label> aucune </label>
        </div>
        </div>

        <br></br>

        <div className="checkbox-options">

        <label> Lésion Hill-Sachs, AP:</label> 

        <div>
          
        <input type="radio" name="Q5_InstabilityScore" value="lésion Hill Sachs visible AP" checked={formState.Q5_InstabilityScore === "lésion Hill Sachs visible AP"} onChange={handleInputChange} />
        <label>visible</label>

        <input type="radio" name="Q5_InstabilityScore" value="pas de Hill Sachs visible AP" checked={formState.Q5_InstabilityScore === "pas de Hill Sachs visible AP"} onChange={handleInputChange} />
        <label> non visible</label>
        </div>
        </div>

        <br></br>

        <div className="checkbox-options">

        <label> Contour glénoidien, AP:</label> 

        <div>
          
        <input type="radio" name="Q6_InstabilityScore" value="perte de contour glénoidien AP" checked={formState.Q6_InstabilityScore === "perte de contour glénoidien AP"} onChange={handleInputChange} />
        <label>perte contour</label>

        <input type="radio" name="Q6_InstabilityScore" value="pas de perte de contour glénoidien" checked={formState.Q6_InstabilityScore === "pas de perte de contour glénoidien"} onChange={handleInputChange} />
        <label> pas de lésion</label>
        </div>
        </div>
        

      
        <div>
        <label>Instability severity index score: </label>
       {InstabilityScore && (
            
              <input type="text" className="small-input" value={InstabilityScore} readOnly />
            
          )}

      </div>
     
     

        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default InstabilityScore;