import React from "react"
import "../../styles/initialCard.css"
import "../../styles/app.css";

function InitialCard({name, description, image, visible, setIsVisible}) {

  const changeVisibleForms = () => {
    setIsVisible(!visible);
  }

  return (
    <div className="card initial">
      <img className="initial-image" src = {image}></img>
      <p className="title"> {name}</p>
      <p className="initial-description">{description}</p>
      <button className={visible ? "button selected":"button unselected"} onClick={changeVisibleForms}>{visible ? "Unselect":"Select"}</button>
    </div>
  )
}
  
export default InitialCard;

/*
This button component conditionally renders with different styles and text based on the value of the 'visible' state.
When clicked, it triggers the 'changeVisibleForms' function to toggle the visibility state between true and false. 
If 'visible' is true, the button displays as "Unselect" and applies the "initialButton-selected" class; otherwise, 
it displays as "Select" with the "initialButton-unselected" class.
*/