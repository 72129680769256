import React from "react";
import "../styles/app.css";

function TextSummary({ setIsFormSended, formDetails }) {

    // Function to render the form details for medical history.
    const renderDetails = () => {
        if (!formDetails) return "No form details available.";

        const answers = Object.values(formDetails).map((answer, index) => (
            <span key={index}>
                {answer}
                <br />
                <br />
            </span>
        ));

        return <p>{answers}</p>;
    };

    return (
        <div className="modal-summary">
            <div className="modal-confirmation-summary">
                <h3 className="confirmation-title-summary">Medical History Summary</h3>
                <div className="confirmation-message-summary">
                    {renderDetails()}
                </div>
                <div className="confirmation-buttons-summary">
                    <button className="button-summary" onClick={() => setIsFormSended(false)}>Close</button>
                </div>
            </div>
        </div>
    );
}

export default TextSummary;