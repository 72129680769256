import React, { useEffect, useState } from "react";
import "../../../styles/app.css";
import Modal from "../../../other-components/Modal.js";

function KneeXray({setNameForm, onFormSubmit}) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [specificImage, setSpecificImage] = useState("");

  const openModalWithSpecificImage = (image) => {
    setSpecificImage(image);
    setIsModalOpen(true);
  }

  const [formState, setFormState] = useState({
    SideKnee:"",
    trochlearDysplasia: "",
    PatellaDysplasia: "",
    CatonDeschampsL1: "",
    CatonDeschampsL2: "",
    InsallSalvati1:"",
    InsallSalvati2:"",
    Blackburn1:"",
    Blackburn2:"",

    AhlbackClassification:"",
    KLclassification:"",
    IwanoClassification:"",


    
  });

  const [showXray, setshowXray] = useState(false);

  const handleChangeshowXray = (event) => {
    setshowXray(event.target.checked);
  }

  const [KneeXray, setKneeXray] = useState({
    "Genou face":false,
    "Genou profile":false,
    "axiale rotule":false,
    "Schuss":false,
    "CT-scan":false
  });



  const handleCheckboxChangeKneeXray = (event) => {
    const { name, checked } = event.target;
     
      setKneeXray(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState({
      ...formState,
      [name]: type === "checkbox" ? checked : value,
    });
  };


  const [CatonDeschampsIndex, setCatonDeschampsIndex] = useState(null);

  useEffect(() => {
    if (formState.CatonDeschampsL1 && formState.CatonDeschampsL2) {
      
      const CatonDechampsValue = (formState.CatonDeschampsL1/formState.CatonDeschampsL2).toFixed(2);
      setCatonDeschampsIndex(CatonDechampsValue);
     } else {
       setCatonDeschampsIndex("");
     }
 }, [formState.CatonDeschampsL1, formState.CatonDeschampsL2]);


 const [InsallSalvatiIndex, setInsallSalvatiIndex] = useState(null);

 useEffect(() => {
    if (formState.InsallSalvati1 && formState.InsallSalvati2) {
      
      const InsallSalvatiValue = (formState.InsallSalvati1/formState.InsallSalvati2).toFixed(2);
      setInsallSalvatiIndex(InsallSalvatiValue);
     } else {
       setInsallSalvatiIndex("");
     }
 }, [formState.InsallSalvati1, formState.InsallSalvati2]);


 const [BlackburnIndex, setBlackburnIndex] = useState(null);

 useEffect(() => {
    if (formState.Blackburn1 && formState.Blackburn2) {
      
      const BlackburnValue = (formState.Blackburn1/formState.Blackburn2).toFixed(2);
      setBlackburnIndex(BlackburnValue);
     } else {
       setBlackburnIndex("");
     }
 }, [formState.Blackburn1, formState.Blackburn2]);


  const handleSubmit = (e) => {
    e.preventDefault();
    // Prepare form details for summary
    setNameForm("Genou natif");

      // Function to convert the state of the checkboxes into a readable string
      const formatCheckboxStateToString = (checkboxState) => {
        const selectedOptions = Object.entries(checkboxState)
            .filter(([_, isChecked]) => isChecked)
            .map(([key, _]) => key) // Get only the keys (option names)
            .join(", ");
  
        return selectedOptions || ""; // If no options are selected, return "None selected"
    };
  

    const KneeXrayText = formatCheckboxStateToString(KneeXray);
      
    


    const formDetails = {
      // Here we convert the form state into a suitable format for display
      "Genou": formState.SideKnee, 
      "Bilan radiologique": KneeXrayText,
      "Dysplasie trochléenne selon Dejour": formState.trochlearDysplasia,
      "Dysplasie patellaire selon Wiberg": formState.PatellaDysplasia,
      "Hauteur rotulienne selon Caton-Deschamps": CatonDeschampsIndex ? `${CatonDeschampsIndex}` : "",
      "Hauteur rotulienne selon Insall-Salvati": InsallSalvatiIndex ? `${InsallSalvatiIndex}` : "",
      "Hauteur rotulienne selon Blackburn": BlackburnIndex ? `${BlackburnIndex}` : "",

      "Trouble dégéneratif selon Ahlback": formState.AhlbackClassification,
      "Trouble dégéneratif selon Kellgren & Lawrence": formState.KLclassification,
      

      "Trouble dégéneratif fémoro-patellaire selon Iwano": formState.IwanoClassification
      
    };




    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object


    // Call the function passed from App.js and pass the details
    onFormSubmit(filteredFormDetails); // Make sure to pass this function correctly from App.js

};

  return (
    <div className="card">
      <p className="title">Bilan radiologique genou</p>
      <form className="specific-form" onSubmit={handleSubmit}>


      <div className="checkbox-options">
    
    <div>
    <input type="radio" name="SideKnee" value="gauche" checked={formState.SideKnee === "gauche"} onChange={handleInputChange} />
    <label> gauche </label>

    <input type="radio" name="SideKnee" value="droit" checked={formState.SideKnee === "droit"} onChange={handleInputChange} />
    <label> droit </label>
    </div>
    </div>


      <div>
   
   <input
       type="checkbox"
       name="showXray"
       onChange={handleChangeshowXray}
       
     />
     <label>Renseigner incidences radiologiques</label>

   </div>

   {showXray && (
<div className="checkbox-options">
{Object.entries(KneeXray).map(([key, value]) => (
  <div key={key}>
    <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeKneeXray} />
    <label>{key}</label>
  </div>
))}
</div>


)}

        


      <div>
          <label>Dysplasie trochléenne:</label>
          <select name="trochlearDysplasia" className="select-input" value={formState.trochlearDysplasia} onChange={handleInputChange} >
            <option value="">-</option>
            <option value="aucune">aucune</option>
            <option value="type A">Type A</option>
            <option value="type B">Type B</option>
            <option value="Type C">Type C</option>
            <option value="Type D">Type D</option>
          </select>
        </div>


        <div>
          <label>Dysplasie patellaire:</label>
          <select name="PatellaDysplasia" className="select-input" value={formState.PatellaDysplasia} onChange={handleInputChange} >
            <option value="">-</option>
            <option value="aucune">aucune</option>
            <option value="type I">Type I</option>
            <option value="type II">Type II</option>
            <option value="Type III">Type III</option>
          </select>
        </div>






      
      <div>
      <label>Hauteur rotulienne, Caton-Deschamps:</label>
      <div>
          <input type="number" className="number-input" name="CatonDeschampsL1" placeholder = "L rotule" min="0" max="230" value={formState.CatonDeschampsL1} onChange={handleInputChange}/>
     
       <input type="number" className="number-input" name="CatonDeschampsL2" placeholder = "L tendon" min="0" max="300" value={formState.CatonDeschampsL2} onChange={handleInputChange}/>
      
       {CatonDeschampsIndex && (
            
            
              <input type="text" className="small-input" value={CatonDeschampsIndex} readOnly />
            
          )}

      </div>
      </div>


      <div>
      <label>Hauteur rotulienne, Insall-Salvati:</label>
      <div>
          <input type="number" className="number-input" name="InsallSalvati1" placeholder = "L rotule" min="0" max="300" value={formState.InsallSalvati1} onChange={handleInputChange}/>
     
       <input type="number" className="number-input" name="InsallSalvati2" placeholder = "L tendon" min="0" max="300" value={formState.InsallSalvati2} onChange={handleInputChange}/>
      
       {InsallSalvatiIndex && (
            
            
              <input type="text" className="small-input" value={InsallSalvatiIndex} readOnly />
            
          )}

      </div>
      </div>



      <div>
      <label>Hauteur rotulienne, Blackburn:</label>
      <div>
          <input type="number" className="number-input" name="Blackburn1" placeholder = "L rotule" min="0" max="300" value={formState.Blackburn1} onChange={handleInputChange}/>
     
       <input type="number" className="number-input" name="Blackburn2" placeholder = "L tendon" min="0" max="300" value={formState.Blackburn2} onChange={handleInputChange}/>
      
       {BlackburnIndex && (
            
            
              <input type="text" className="small-input" value={BlackburnIndex} readOnly />
            
          )}

      </div>
      </div>

      <label className = "title-label-within-card" > Troubles dégéneratifs: </label>

      <div>
          <select name="KLclassification" className="select-input" value={formState.KLclassification} onChange={handleInputChange} >
            <option value="">Kellgren & Lawrence:</option>
            <option value="grade 0: aucun signe d'atteinte dégénerative">grade 0: aucun signe d'atteinte dégénerative</option>
            <option value="">Prédominance interne:</option>
            <option value="grade 1 interne; minime pincement interligne, possible débord ostéophytaire">grade 1: minime pincement interligne, possible débord ostéophytaire</option>
            <option value="grade 2 interne; ostéophytes, possible pincement interligne">grade 2: ostéophytes, possible pincement interligne</option>
            <option value="grade 3 interne; ostéophytose modérée, pincement interligne marqué, sclérose sous-chondrale, possible déformation osseuse">grade 3: ostéophytose modérée, pincement interligne marqué, sclérose sous-chondrale, possible déformation osseuse</option>
            <option value="grade 4 interne; ostéophytose marquée, pincement interligne, sclérose sous-chondrale importante, déformation osseuse">grade 4: ostéophytose marquée, pincement interligne, sclérose sous-chondrale importante, déformation osseuse</option>
            <option value="">Prédominance externe:</option>
            <option value="grade 1 externe; minime pincement interligne, possible débord ostéophytaire">grade 1: minime pincement interligne, possible débord ostéophytaire</option>
            <option value="grade 2 externe; ostéophytes, possible pincement interligne">grade 2: ostéophytes, possible pincement interligne</option>
            <option value="grade 3 externe; ostéophytose modérée, pincement interligne marqué, sclérose sous-chondrale, possible déformation osseuse">grade 3: ostéophytose modérée, pincement interligne marqué, sclérose sous-chondrale, possible déformation osseuse</option>
            <option value="grade 4 externe; ostéophytose marquée, pincement interligne, sclérose sous-chondrale importante, déformation osseuse">grade 4: ostéophytose marquée, pincement interligne, sclérose sous-chondrale importante, déformation osseuse</option>
          </select>
        
          <select name="AhlbackClassification" className="select-input" value={formState.AhlbackClassification} onChange={handleInputChange} >
            <option value="">Ahlback:</option>
            <option value="grade 0: aucun signe d'atteinte dégénerative">grade 0: aucun signe d'atteinte dégénerative</option>
            <option value="">Prédominance interne:</option>
            <option value="grade 1 interne; pincement interligne, 3mm ou moins">grade 1: pincement interligne, 3mm ou moins</option>
            <option value="grade 2 interne; disparition interligne">grade 2: disparition interligne</option>
            <option value="grade 3 interne; attrition osseuse discrète (0-5 mm)">grade 3: attrition osseuse discrète (0-5 mm)</option>
            <option value="grade 4 interne; attrition osseuse modérée (5-10 mm)">grade 4: attrition osseuse modérée (5-10 mm)</option>
            <option value="grade 5 interne; attrition osseuse sévère (plus de de 10mm)">grade 5: attrition osseuse sévère (plus de de 10mm)</option>
            <option value="">Prédominance externe:</option>
            <option value="grade 1 externe; pincement interligne, 3mm ou moins">grade 1: pincement interligne, 3mm ou moins</option>
            <option value="grade 2 externe; disparition interligne">grade 2: disparition interligne</option>
            <option value="grade 3 externe; attrition osseuse discrète (0-5 mm)">grade 3: attrition osseuse discrète (0-5 mm)</option>
            <option value="grade 4 externe; attrition osseuse modérée (5-10 mm)">grade 4: attrition osseuse modérée (5-10 mm)</option>
            <option value="grade 5 externe; attrition osseuse sévère (plus de de 10mm)">grade 5: attrition osseuse sévère (plus de de 10mm)</option>
          </select>
        
          <select name="IwanoClassification" className="select-input" value={formState.IwanoClassification} onChange={handleInputChange} >
            <option value="">Iwano:</option>
            <option value="grade 1: pas de pincement interligne fémoro-patellaire">grade 1: pas de pincement interligne fémoro-patellaire</option>
            <option value="grade 2: pincement interligne, distance minimale supérieure à 3mm">grade 2: pincement interligne, distance minimale supérieure à 3mm</option>
            <option value="grade 3: pincement interligne, distance minimale inférieure à 3mm">grade 3: pincement interligne, distance minimale inférieure à 3mm</option>
            <option value="grade 4: disparition interligne fémoro-patellaire">grade 4: disparition interligne fémoro-patellaire</option>
        
          </select>
        </div>

      




        <button type="submit" className="button send">Send</button>
      </form>

      <br></br>
      <button className="button modal-trigger" onClick={() => openModalWithSpecificImage("./Illustrations/PatellarHight.png")}>Help: hauteur rotule</button>
      <br></br>
      <button className="button modal-trigger" onClick={() => openModalWithSpecificImage("./Illustrations/DejourClassification.png")}>Help: Dejour</button>
      <br></br>
      <button className="button modal-trigger" onClick={() => openModalWithSpecificImage("./Illustrations/WibergClassification.png")}>Help: Wiberg</button>
     

   

      {isModalOpen && ( /* If isModalOpen is true, <Modal> will be displayed. */
        <Modal imageSrc={specificImage} onClose={() => setIsModalOpen(false)} />
      )}
    </div>
  );
}

export default KneeXray;