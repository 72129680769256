// Allergies.js
import React, { useEffect, useState } from 'react';
import '../../../styles/app.css';

function OxfordKneeScore({ setNameForm, onFormSubmit }) {
  const [formState, setFormState] = useState({
    SideOxfordScore:'',
    Q1_OxfordScore: '',
    Q2_OxfordScore: '',
    Q3_OxfordScore: '',
    Q4_OxfordScore: '',
    Q5_OxfordScore: '',
    Q6_OxfordScore: '',
    Q7_OxfordScore: '',
    Q8_OxfordScore: '',
    Q9_OxfordScore: '',
    Q10_OxfordScore: '',
    Q11_OxfordScore: '',
    Q12_OxfordScore: '',
  });



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const [OxfordKneeScore, setOxfordKneeScore] = useState(null);

  useEffect(() => {
    if (formState.Q1_OxfordScore && formState.Q2_OxfordScore &&
      formState.Q3_OxfordScore && formState.Q4_OxfordScore &&
      formState.Q5_OxfordScore && formState.Q6_OxfordScore &&
      formState.Q7_OxfordScore && formState.Q8_OxfordScore &&
      formState.Q9_OxfordScore && formState.Q10_OxfordScore &&
      formState.Q11_OxfordScore && formState.Q12_OxfordScore

    ) {
      
      var OxfordKneeScoreValue = 0;
      if (formState.Q1_OxfordScore === "Les douleurs sont considérées comme inexistantes") {OxfordKneeScoreValue += 4;}
      if (formState.Q1_OxfordScore === "Les douleurs sont considérées comme très légères") {OxfordKneeScoreValue += 3;}
      if (formState.Q1_OxfordScore === "Les douleurs sont considérées comme légères") {OxfordKneeScoreValue += 2;}
      if (formState.Q1_OxfordScore === "Les douleurs sont considérées comme modérées") {OxfordKneeScoreValue += 1;}
      if (formState.Q1_OxfordScore === "Les douleurs sont considérées comme sévères") {OxfordKneeScoreValue += 0;}

      if (formState.Q2_OxfordScore === "L’hygiène personnelle (douche/bain) s'effectue sans difficulté") {OxfordKneeScoreValue += 4;}
      if (formState.Q2_OxfordScore === "L’hygiène personnelle (douche/bain) est légèrement difficile") {OxfordKneeScoreValue += 3;}
      if (formState.Q2_OxfordScore === "L’hygiène personnelle (douche/bain) est modérèment difficile") {OxfordKneeScoreValue += 2;}
      if (formState.Q2_OxfordScore === "L’hygiène personnelle (douche/bain) est extrêmement difficile") {OxfordKneeScoreValue += 1;}
      if (formState.Q2_OxfordScore === "L’hygiène personnelle (douche/bain) est impossible seul") {OxfordKneeScoreValue += 0;}

      if (formState.Q3_OxfordScore === "Les transports (entrer/sortir d'une voiture, utilisation des transports publics) s'effectue sans difficulté") {OxfordKneeScoreValue += 4;}
      if (formState.Q3_OxfordScore === "Les transports (entrer/sortir d'une voiture, utilisation des transports publics) sont légèrement difficiles") {OxfordKneeScoreValue += 3;}
      if (formState.Q3_OxfordScore === "Les transports (entrer/sortir d'une voiture, utilisation des transports publics) sont modérèment difficiles") {OxfordKneeScoreValue += 2;}
      if (formState.Q3_OxfordScore === "Les transports (entrer/sortir d'une voiture, utilisation des transports publics) sont extrêmement difficiles") {OxfordKneeScoreValue += 1;}
      if (formState.Q3_OxfordScore === "Les transports (entrer/sortir d'une voiture, utilisation des transports publics) sont impossibles") {OxfordKneeScoreValue += 0;}

      if (formState.Q4_OxfordScore === "Les douleurs ne limitent pas le périmètre de marche, ou supérieur à 30 minutes") {OxfordKneeScoreValue += 4;}
      if (formState.Q4_OxfordScore === "Les douleurs limitent le périmètre de marche à 15-30 minutes") {OxfordKneeScoreValue += 3;}
      if (formState.Q4_OxfordScore === "Les douleurs limitent le périmètre de marche à 5-15 minutes") {OxfordKneeScoreValue += 2;}
      if (formState.Q4_OxfordScore === "Les douleurs limitent la marche à l'intérieur du domicile") {OxfordKneeScoreValue += 1;}
      if (formState.Q4_OxfordScore === "Les douleurs limitent la marche à quelques pas") {OxfordKneeScoreValue += 0;}

     
      if (formState.Q5_OxfordScore === "Le passage de la position assise à la position debout est indolore") {OxfordKneeScoreValue += 4;}
      if (formState.Q5_OxfordScore === "Le passage de la position assise à la position debout est légèrement douloureux") {OxfordKneeScoreValue += 3;}
      if (formState.Q5_OxfordScore === "Le passage de la position assise à la position debout est modérement douloureux") {OxfordKneeScoreValue += 2;}
      if (formState.Q5_OxfordScore === "Le passage de la position assise à la position debout est très douloureux") {OxfordKneeScoreValue += 1;}
      if (formState.Q5_OxfordScore === "Le passage de la position assise à la position debout est impossible") {OxfordKneeScoreValue += 0;}
     
      if (formState.Q6_OxfordScore === "Il n'y a pas de boiterie à la marche , ou rarement") {OxfordKneeScoreValue += 4;}
      if (formState.Q6_OxfordScore === "Une boiterie à la marche est occasionnelle, ou uniquement au démarrage") {OxfordKneeScoreValue += 3;}
      if (formState.Q6_OxfordScore === "Une boiterie à la marche est fréquente") {OxfordKneeScoreValue += 2;}
      if (formState.Q6_OxfordScore === "Une boiterie à la marche est presque constante") {OxfordKneeScoreValue += 1;}
      if (formState.Q6_OxfordScore === "Une boiterie à la marche est constamment présente") {OxfordKneeScoreValue += 0;}
     
      if (formState.Q7_OxfordScore === "Le passage de la position agenouillée à la position debout s'effectue sans difficulté") {OxfordKneeScoreValue += 4;}
      if (formState.Q7_OxfordScore === "Le passage de la position agenouillée à la position debout s'effectue avec peu de difficulté") {OxfordKneeScoreValue += 3;}
      if (formState.Q7_OxfordScore === "Le passage de la position agenouillée à la position debout s'effectue avec difficulté") {OxfordKneeScoreValue += 2;}
      if (formState.Q7_OxfordScore === "Le passage de la position agenouillée à la position debout s'effectue avec beaucoup de difficulté") {OxfordKneeScoreValue += 1;}
      if (formState.Q7_OxfordScore === "Le passage de la position agenouillée à la position debout est impossible") {OxfordKneeScoreValue += 0;}

      if (formState.Q8_OxfordScore === "Les douleurs nocturnes sont inexistantes") {OxfordKneeScoreValue += 4;}
      if (formState.Q8_OxfordScore === "Les douleurs nocturnes sont présentes une à deux nuits sur le dernier mois") {OxfordKneeScoreValue +=3;}
      if (formState.Q8_OxfordScore === "Les douleurs nocturnes sont présentes occasionnellement sur le dernier mois") {OxfordKneeScoreValue +=2;}
      if (formState.Q8_OxfordScore === "Les douleurs nocturnes sont présentes la plupart des nuits sur le dernier mois") {OxfordKneeScoreValue = OxfordKneeScoreValue +=1;}
      if (formState.Q8_OxfordScore === "Les douleurs nocturnes sont présentes toutes les nuits sur le dernier mois") {OxfordKneeScoreValue = OxfordKneeScoreValue +=0;}



      if (formState.Q9_OxfordScore === "Les douleurs n'interfèrent pas avec les activités habituelles") {OxfordKneeScoreValue += 4;}
      if (formState.Q9_OxfordScore === "Les douleurs interfèrent rarement avec les activités habituelles") {OxfordKneeScoreValue += 3;}
      if (formState.Q9_OxfordScore === "Les douleurs interfèrent modérement avec les activités habituelles") {OxfordKneeScoreValue += 2;}
      if (formState.Q9_OxfordScore === "Les douleurs interfèrent fréquement avec les activités habituelles") {OxfordKneeScoreValue += 1;}
      if (formState.Q9_OxfordScore === "Les douleurs interfèrent constamment avec les activités habituelles") {OxfordKneeScoreValue += 0;}

      if (formState.Q10_OxfordScore === "Pas de crainte de lâchage, ou très rarement") {OxfordKneeScoreValue += 4;}
      if (formState.Q10_OxfordScore === "Crainte de lâchage rare, ou uniquement au démarrage") {OxfordKneeScoreValue += 3;}
      if (formState.Q10_OxfordScore === "Crainte de lâchage fréquente") {OxfordKneeScoreValue += 2;}
      if (formState.Q10_OxfordScore === "Crainte de lâchage presque constante") {OxfordKneeScoreValue += 1;}
      if (formState.Q10_OxfordScore === "Crainte de lâchage constante") {OxfordKneeScoreValue += 0;}

      if (formState.Q11_OxfordScore === "La sortie du domicile s'effectue sans difficulté") {OxfordKneeScoreValue += 4;}
      if (formState.Q11_OxfordScore === "La sortie du domicile s'effectue avec peu de difficulté") {OxfordKneeScoreValue += 3;}
      if (formState.Q11_OxfordScore === "La sortie du domicile s'effectue avec difficulté") {OxfordKneeScoreValue += 2;}
      if (formState.Q11_OxfordScore === "La sortie du domicile est extrêment difficile") {OxfordKneeScoreValue += 1;}
      if (formState.Q11_OxfordScore === "La sortie du domicile est impossible") {OxfordKneeScoreValue += 0;}

     
      if (formState.Q12_OxfordScore === "La descente d’un étage en escalier s'effectue sans difficulté") {OxfordKneeScoreValue += 4;}
      if (formState.Q12_OxfordScore === "La descente d’un étage en escalier s'effectue avec peu de difficulté") {OxfordKneeScoreValue += 3;}
      if (formState.Q12_OxfordScore === "La descente d’un étage en escalier s'effectue avec difficulté") {OxfordKneeScoreValue += 2;}
      if (formState.Q12_OxfordScore === "La descente d’un étage en escalier est extrêment difficile") {OxfordKneeScoreValue += 1;}
      if (formState.Q12_OxfordScore === "La descente d’un étage en escalier est impossible") {OxfordKneeScoreValue += 0;}

  
     
      setOxfordKneeScore(OxfordKneeScoreValue);
     } 

     
     else {
       setOxfordKneeScore("");
     }
 }, [formState.Q1_OxfordScore, formState.Q2_OxfordScore,
  formState.Q3_OxfordScore, formState.Q4_OxfordScore,
  formState.Q5_OxfordScore, formState.Q6_OxfordScore,
  formState.Q7_OxfordScore, formState.Q8_OxfordScore,
  formState.Q9_OxfordScore, formState.Q10_OxfordScore,
  formState.Q11_OxfordScore, formState.Q12_OxfordScore]);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    setNameForm('Oxford Knee Score');


    const formDetails = {
    'SideOxfordScore': "Questions spécifiques à l'Oxford Score, genou " + formState.SideOxfordScore + ":",
    'Q1-12_OxfordScore': [formState.Q1_OxfordScore, formState.Q2_OxfordScore, formState.Q3_OxfordScore,formState.Q4_OxfordScore,formState.Q5_OxfordScore, formState.Q6_OxfordScore, 
    formState.Q7_OxfordScore,formState.Q8_OxfordScore,formState.Q9_OxfordScore, formState.Q10_OxfordScore,formState.Q11_OxfordScore,formState.Q12_OxfordScore]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join('. '),
    
    'score value': "Score calculé: " + OxfordKneeScore + "/48"
     
      
    };

    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  return (
    <div className="card">
      <p className="title">Oxford Knee score</p>
      <form className="specific-form" onSubmit={handleSubmit}>


      <div className="checkbox-options">
    
        <div>
        <input type="radio" name="SideOxfordScore" value="gauche" checked={formState.SideOxfordScore === "gauche"} onChange={handleInputChange} />
        <label> genou gauche </label>

        <input type="radio" name="SideOxfordScore" value="droit" checked={formState.SideOxfordScore === "droit"} onChange={handleInputChange} />
        <label> genou droit </label>
        </div>
        </div>

      <label>Questions portant sur les 4 dernières semaines: <br></br></label>

      <br></br>

        <div>
          <select name="Q1_OxfordScore" className="select-input" value={formState.Q1_OxfordScore} onChange={handleInputChange} required >
            <option value="">Q1: Evaluation douleurs:</option>
            <option value="Les douleurs sont considérées comme inexistantes">Inexistantes</option>
            <option value="Les douleurs sont considérées comme très légères">Très légères</option>
            <option value="Les douleurs sont considérées comme légères">Légères</option>
            <option value="Les douleurs sont considérées comme modérées">Modérées</option>
            <option value="Les douleurs sont considérées comme sévères">Sévères</option>
          </select>
        </div>


        <div>
        <label>Q2: Hygiène personnelle {'('}douche/bain{')'}:</label> 
          <select name="Q2_OxfordScore" className="select-input" value={formState.Q2_OxfordScore} onChange={handleInputChange} required >
            <option value="">---</option>
            <option value="L’hygiène personnelle (douche/bain) s'effectue sans difficulté">Sans difficulté</option>
            <option value="L’hygiène personnelle (douche/bain) est légèrement difficile">Légèrement difficile</option>
            <option value="L’hygiène personnelle (douche/bain) est modérèment difficile">Modérèment difficile</option>
            <option value="L’hygiène personnelle (douche/bain) est extrêmement difficile">Extrêmement difficile</option>
            <option value="L’hygiène personnelle (douche/bain) est impossible seul">Impossible seul</option>
          </select>
        </div>

        <div>
        <label>Q3: Transports {'('}entrer/sortir d'une voiture, transports publics{')'}:</label>
          <select name="Q3_OxfordScore" className="select-input" value={formState.Q3_OxfordScore} onChange={handleInputChange} required >
          <option value="">---</option>
            <option value="Les transports (entrer/sortir d'une voiture, utilisation des transports publics) s'effectue sans difficulté">Sans difficulté</option>
            <option value="Les transports (entrer/sortir d'une voiture, utilisation des transports publics) sont légèrement difficiles">Légèrement difficile</option>
            <option value="Les transports (entrer/sortir d'une voiture, utilisation des transports publics) sont modérèment difficiles">Modérèment difficile</option>
            <option value="Les transports (entrer/sortir d'une voiture, utilisation des transports publics) sont extrêmement difficiles">Extrêmement difficile</option>
            <option value="Les transports (entrer/sortir d'une voiture, utilisation des transports publics) sont impossibles">Impossible</option>
          </select>
        </div>

        <div>
        <label>Q4: Limitation périmètre de marche:</label>
          <select name="Q4_OxfordScore" className="select-input" value={formState.Q4_OxfordScore} onChange={handleInputChange} required >
          <option value="">---</option>
            <option value="Les douleurs ne limitent pas le périmètre de marche, ou supérieur à 30 minutes">Pas de limitation, ou supérieur à 30 minutes</option>
            <option value="Les douleurs limitent le périmètre de marche à 15-30 minutes">15-30 minutes</option>
            <option value="Les douleurs limitent le périmètre de marche à 5-15 minutes">5-15 minutes</option>
            <option value="Les douleurs limitent la marche à l'intérieur du domicile">Intérieur du domicile</option>
            <option value="Les douleurs limitent la marche à quelques pas">Quelques pas</option>
          </select>
        </div>    

        <div>
        <label>Q5: Passage position assise à debout:</label>
        
          <select name="Q5_OxfordScore" className="select-input" value={formState.Q5_OxfordScore} onChange={handleInputChange} required >
          <option value="">---</option>
            <option value="Le passage de la position assise à la position debout est indolore">Indolore</option>
            <option value="Le passage de la position assise à la position debout est légèrement douloureux">Légèrement douloureux</option>
            <option value="Le passage de la position assise à la position debout est modérement douloureux">Modérement douloureux</option>
            <option value="Le passage de la position assise à la position debout est très douloureux">très douloureux</option>
            <option value="Le passage de la position assise à la position debout est impossible">Impossible</option>
          </select>
        </div>

        <div>
        <label>Q6: Boiterie à la marche:</label>
          <select name="Q6_OxfordScore" className="select-input" value={formState.Q6_OxfordScore} onChange={handleInputChange} required >
          <option value="">---</option>
            <option value="Il n'y a pas de boiterie à la marche , ou rarement">Rare ou jamais présente</option>
            <option value="Une boiterie à la marche est occasionnelle, ou uniquement au démarrage">Occasionnelle, ou uniquement au démarrage</option>
            <option value="Une boiterie à la marche est fréquente">Fréquente</option>
            <option value="Une boiterie à la marche est presque constante">Presque constante</option>
            <option value="Une boiterie à la marche est constamment présente">Constamment présente</option>
          </select>
        </div>



        <div>
        <label>Q7: Passage position agenouillée à debout:</label>
          <select name="Q7_OxfordScore" className="select-input" value={formState.Q7_OxfordScore} onChange={handleInputChange} required >
          <option value="">---</option>
            <option value="Le passage de la position agenouillée à la position debout s'effectue sans difficulté">Sans difficulté</option>
            <option value="Le passage de la position agenouillée à la position debout s'effectue avec peu de difficulté">Avec peu de difficulté</option>
            <option value="Le passage de la position agenouillée à la position debout s'effectue avec difficulté">Avec difficulté</option>
            <option value="Le passage de la position agenouillée à la position debout s'effectue avec beaucoup de difficulté">Avec beaucoup de difficulté</option>
            <option value="Le passage de la position agenouillée à la position debout est impossible">Impossible</option>
          </select>
        </div>

        <div>
        <label>Q8: Douleurs nocturnes:</label>
      
          <select name="Q8_OxfordScore" className="select-input" value={formState.Q8_OxfordScore} onChange={handleInputChange} required >
          <option value="">---</option>
            <option value="Les douleurs nocturnes sont inexistantes">Inexistantes</option>
            <option value="Les douleurs nocturnes sont présentes une à deux nuits sur le dernier mois">une à deux nuits</option>
            <option value="Les douleurs nocturnes sont présentes occasionnellement sur le dernier mois">occasionnellement</option>
            <option value="Les douleurs nocturnes sont présentes la plupart des nuits sur le dernier mois">la plupart des nuits</option>
            <option value="Les douleurs nocturnes sont présentes toutes les nuits sur le dernier mois">Toutes les nuits</option>
          </select>
        </div>

       

        <div>
        <label>Q9: Limitation activités habituelles:</label>
          <select name="Q9_OxfordScore" className="select-input" value={formState.Q9_OxfordScore} onChange={handleInputChange} required >
          <option value="">---</option>
            <option value="Les douleurs n'interfèrent pas avec les activités habituelles">Jamais</option>
            <option value="Les douleurs interfèrent rarement avec les activités habituelles">Rarement</option>
            <option value="Les douleurs interfèrent modérement avec les activités habituelles">Modérement</option>
            <option value="Les douleurs interfèrent fréquement avec les activités habituelles">fréquement</option>
            <option value="Les douleurs interfèrent constamment avec les activités habituelles">Constamment</option>
          </select>
        </div>


        <div>
        <label> Q10: Crainte de lâchage:</label>
          <select name="Q10_OxfordScore" className="select-input" value={formState.Q10_OxfordScore} onChange={handleInputChange} required >
          <option value="">---</option>
            <option value="Pas de crainte de lâchage, ou très rarement">très rarement/jamais</option>
            <option value="Crainte de lâchage rare, ou uniquement au démarrage">rare, ou uniquement au démarrage</option>
            <option value="Crainte de lâchage fréquente">fréquente</option>
            <option value="Crainte de lâchage presque constante">presque constante</option>
            <option value="Crainte de lâchage constante">constante</option>
          </select>
        </div>


        <div>
        <label>Q11: Sortie du domicile:</label>
          <select name="Q11_OxfordScore" className="select-input" value={formState.Q11_OxfordScore} onChange={handleInputChange} required >
          <option value="">---</option>
            <option value="La sortie du domicile s'effectue sans difficulté">Sans difficulté</option>
            <option value="La sortie du domicile s'effectue avec peu de difficulté">Avec peu de difficulté</option>
            <option value="La sortie du domicile s'effectue avec difficulté">Avec difficulté</option>
            <option value="La sortie du domicile est extrêment difficile">Extrêmement difficile</option>
            <option value="La sortie du domicile est impossible">Impossible</option>
          </select>
         
        </div>

        <div>
        <label>Q12: Descente d’un étage en escalier:</label>
       
          <select name="Q12_OxfordScore" className="select-input" value={formState.Q12_OxfordScore} onChange={handleInputChange} required >
          <option value="">---</option>
            <option value="La descente d’un étage en escalier s'effectue sans difficulté">Sans difficulté</option>
            <option value="La descente d’un étage en escalier s'effectue avec peu de difficulté">Avec peu de difficulté</option>
            <option value="La descente d’un étage en escalier s'effectue avec difficulté">Avec difficulté</option>
            <option value="La descente d’un étage en escalier est extrêment difficile">Extrêmement difficile</option>
            <option value="La descente d’un étage en escalier est impossible">Impossible</option>
          </select>
        
        </div>

      
        <div>
        <label>Score: </label>
       {OxfordKneeScore && (
            
              <input type="text" className="small-input" value={OxfordKneeScore} readOnly />
            
          )}

      </div>
     

        

        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default OxfordKneeScore;