import React, { useEffect, useState } from "react";
import "../../../styles/app.css";
import Modal from "../../../other-components/Modal.js";

function ShoulderXray({setNameForm, onFormSubmit}) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [specificImage, setSpecificImage] = useState("");

  const openModalWithSpecificImage = (image) => {
    setSpecificImage(image);
    setIsModalOpen(true);
  }

  const [formState, setFormState] = useState({
    SideShoulder:"",
    Dislocation: "",
    Hill_Sachs_lesion:"",
    Bankart_Lesion:"",
    OA_KellgrenLawrence: "",
    CTA_Hamada:"",
    OA_Walch:"",
    ShoulderIndex:"",

    Deltoid_tuberosity_index_Value1:"",
    Deltoid_tuberosity_index_Value2:""
    
  });

  const [showXray, setshowXray] = useState(false);

  const handleChangeshowXray = (event) => {
    setshowXray(event.target.checked);
  }

  const [Xray, setXray] = useState({
    "face":false,
    "Neer":false,
    "axiale":false,
    "CT-scan":false
  });



  const handleCheckboxChangeXray = (event) => {
    const { name, checked } = event.target;
     
      setXray(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState({
      ...formState,
      [name]: type === "checkbox" ? checked : value,
    });
  };


  const [Deltoid_tuberosity_index, setDeltoid_tuberosity_index] = useState(null);

  useEffect(() => {
    if (formState.Deltoid_tuberosity_index_Value1 && formState.Deltoid_tuberosity_index_Value2) {
      
      const Deltoid_tuberosity_index_Value = (formState.Deltoid_tuberosity_index_Value1/formState.Deltoid_tuberosity_index_Value2).toFixed(2);
      setDeltoid_tuberosity_index(Deltoid_tuberosity_index_Value);
     } else {
      setDeltoid_tuberosity_index("");
     }
 }, [formState.Deltoid_tuberosity_index_Value1, formState.Deltoid_tuberosity_index_Value2]);





  const handleSubmit = (e) => {
    e.preventDefault();
    // Prepare form details for summary
    setNameForm("Epaule");

      // Function to convert the state of the checkboxes into a readable string
      const formatCheckboxStateToString = (checkboxState) => {
        const selectedOptions = Object.entries(checkboxState)
            .filter(([_, isChecked]) => isChecked)
            .map(([key, _]) => key) // Get only the keys (option names)
            .join(", ");
  
        return selectedOptions || ""; // If no options are selected, return "None selected"
    };
  

    const XrayText = formatCheckboxStateToString(Xray);
      
    


    const formDetails = {
      // Here we convert the form state into a suitable format for display
     

      "Epaule": formState.SideShoulder,
      "Bilan radiologique": XrayText,
      "Articulation gléno-humérale": [formState.Dislocation,formState.Hill_Sachs_lesion, formState.Bankart_Lesion].filter(function(val) {
        return val;
      }).join(', '),
      

      "Trouble dégéneratif selon Kellgren & Lawrence": formState.OA_KellgrenLawrence,
      "Morphologie glène selon Walch": formState.OA_Walch,
      "Cuff tear arthropathy selon Hamada": formState.CTA_Hamada,
      "Index tubérosité deltoidienne": Deltoid_tuberosity_index ? `${Deltoid_tuberosity_index}` : "",
      "Shoulder Index": formState.ShoulderIndex ? `${formState.ShoulderIndex} ° ` : "",

    };




    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object


    // Call the function passed from App.js and pass the details
    onFormSubmit(filteredFormDetails); // Make sure to pass this function correctly from App.js

};

  return (
    <div className="card">
      <p className="title">Bilan radiologique épaule</p>
      <form className="specific-form" onSubmit={handleSubmit}>


      <div className="checkbox-options">
    
    <div>
    <input type="radio" name="SideShoulder" value="gauche" checked={formState.SideShoulder === "gauche"} onChange={handleInputChange} />
    <label> gauche </label>

    <input type="radio" name="SideShoulder" value="droite" checked={formState.SideShoulder === "droite"} onChange={handleInputChange} />
    <label> droite </label>
    </div>
    </div>

    <br></br>


      <div>
   
   <input
       type="checkbox"
       name="showXray"
       onChange={handleChangeshowXray}
       
     />
     <label>Renseigner incidences radiologiques</label>

   </div>

   {showXray && (
<div className="checkbox-options">
{Object.entries(Xray).map(([key, value]) => (
  <div key={key}>
    <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeXray} />
    <label>{key}</label>
  </div>
))}
</div>


)}

        


      <div>
          <label>Rapports articulaires:</label>
          <select name="Dislocation" className="select-input" value={formState.Dislocation} onChange={handleInputChange} >
            <option value="">-</option>
            <option value="Rapport articulaires conservés">conservés</option>
            <option value="luxation antéro-inférieure">luxation antéro-inférieure</option>
            <option value="luxation postérieure">luxation postérieure</option>
            <option value="luxation erecta">luxation erecta</option>
          </select>
        </div>

        <div>
          <label>Hill-Sachs: &emsp; </label>
          
          <input type="radio" name="Hill_Sachs_lesion" value="pas de lésion type Hill-Sachs" checked={formState.Hill_Sachs_lesion === "pas de lésion type Hill-Sachs"} onChange={handleInputChange} />
            <label> - </label>
         
            <input type="radio" name="Hill_Sachs_lesion" value="lésion type Hill-Sachs" checked={formState.Hill_Sachs_lesion === "lésion type Hill-Sachs"} onChange={handleInputChange} />
            <label> + </label>
        </div>

        <div>
          <label>Bankart: &emsp; &emsp;</label>
          
          <input type="radio" name="Bankart_Lesion" value="pas de lésion type Bankart" checked={formState.Bankart_Lesion === "pas de lésion type Bankart"} onChange={handleInputChange} />
            <label> - </label>
         
            <input type="radio" name="Bankart_Lesion" value="lésion type Bankart" checked={formState.Bankart_Lesion === "lésion type Bankart"} onChange={handleInputChange} />
            <label> + </label>
        </div>

        <select name="OA_KellgrenLawrence" className="select-input" value={formState.OA_KellgrenLawrence} onChange={handleInputChange} >
           <option value="">Atteinte dégénerative selon Kellgren Lawrence:</option>
           <option value="Stade 0; pas de signe d'arthrose">Stade 0; pas de signe d'arthrose</option>
           <option value="Stade 1; minimes ostéophytes, pincement articulaire discret">Stade 1; minimes ostéophytes, pincement articulaire discret</option>
           <option value="Stade 2; discrète géodes, pincement interligne modéré, perte modérée de sphéricité de la tête fémorale">Stade 2; ostéphoytes ou pincement articulaire</option>
           <option value="Stade 3; pincement interligne et sclérose sous-chondrale">Stade 3; pincement interligne et sclérose sous-chondrale</option>
           <option value="Stade 4; pincement complet de l'interlgne articulaire">Stade 4; pincement complet de l'interlgne articulaire</option>
         </select>

         <select name="OA_Walch" className="select-input" value={formState.OA_Walch} onChange={handleInputChange} >
           <option value="">Morphologie glène selon Walch:</option>
           <option value="Type A1: tête humérale centrée, érosion mineure">Type A1: tête humérale centrée, érosion mineure</option>
           <option value="Type A2: tête humérale centrée, érosion majeure">Type A2: tête humérale centrée, érosion majeure</option>
           <option value="Type B1: subluxation postéreiure sur pincement interligne, sclérose et ostéophytes postérieurs">Type B1: subluxation postéreiure sur pincement interligne, sclérose et ostéophytes postérieurs</option>
           <option value="Type B2: subluxation postérieure sur rétroversion glène et usure postérieure">Type B2: subluxation postérieure sur rétroversion glène et usure postérieure</option>
           <option value="Type C: rétroversion > 25, avec ou sans érosion">Type C: rétroversion {'>'} 25, avec ou sans érosion</option>
         </select>



         <select name="CTA_Hamada" className="select-input" value={formState.CTA_Hamada} onChange={handleInputChange} >
           <option value="">"Cuff tear arthropathy" selon Hamada:</option>
           <option value="Stade 1; intervalle acromio-huméral > 6mm">Stade 1; intervalle acromio-huméral {'>'} 6mm</option>
           <option value="Stade 2; intervalle acromio-huméral < 5mm">Stade 2; intervalle acromio-huméral {'<'} 5mm</option>
           <option value="Stade 3; stade 2 avec acétabulisation de l'acromion">Stade 3; grade 2 + acétabulisation</option>
           <option value="Stade 4; stade 3 avec pincement gléno-huméral">Stade 4; stade 3 + pincement gléno-huméral</option>
           <option value="Stade 5; perte osseuse, effondrement tête humérale">Stade 5; perte osseuse, effondrement tête humérale</option>
         </select>

         <div>
          <br></br>
      <label>Index tuberosité deltoidienne:</label>
      <div>
          <input type="number" className="number-input" name="Deltoid_tuberosity_index_Value1" placeholder = "D externe"  value={formState.Deltoid_tuberosity_index_Value1} onChange={handleInputChange}/>
     
       <input type="number" className="number-input" name="Deltoid_tuberosity_index_Value2" placeholder = "D interne"  value={formState.Deltoid_tuberosity_index_Value2} onChange={handleInputChange}/>
      
       {Deltoid_tuberosity_index && (
            
            
              <input type="text" className="small-input" value={Deltoid_tuberosity_index} readOnly />
            
          )}

      </div>
      </div>

      <label> "Shoulder Index": </label>
            <div>
          <input type="number" className="number-input" name="ShoulderIndex" min="0" max="180" value={formState.ShoulderIndex} onChange={handleInputChange} />
         
       </div>




        <button type="submit" className="button send">Send</button>
      </form>

    
   

      {isModalOpen && ( /* If isModalOpen is true, <Modal> will be displayed. */
        <Modal imageSrc={specificImage} onClose={() => setIsModalOpen(false)} />
      )}
    </div>
  );
}

export default ShoulderXray;