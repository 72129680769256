import React, { useState } from "react";
import "../../../styles/app.css";

function Hip({setNameForm, onFormSubmit}) { 
  const [formState, setFormState] = useState({
 
    sideHip: "",
    legDisHip: "",
    quantLenghtHip: "",
    skinHip: "pas de plaie, pas de cicatrice",
    skin2Hip: "",
    
    extensionHip: "",
    flexionHip: "",
    neutralFEHip: "",

    abdHip: "",
    addHip: "",
    neutralAAHip: "",

    reHip: "",
    riHip: "",
    NeutralRotationHip: "",
    
    lasegue:"",
    trendelburgHip: "",
    fadirHip: "",
    faberHip: "",
    drehmannHip: "",
    thomasHip: ""

  });


  const defaultValues = {
    flexionHip: 120,
    neutralFEHip: 0,
    extensionHip: 20,
    abdHip: 45,
    neutralAAHip: 0,
    addHip: 20,
    reHip: 45,
    riHip: 30,
    NeutralRotationHip: 0
  };

  const [TNVbasic, setTNVbasic] = useState({
    "absence de trouble neuro-vasculaire": false,
  });

  const handleCheckboxChangeTNVbasic = (event) => {
    const { name, checked } = event.target;
     
      setTNVbasic(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Examen clinique hanche");

     // Function to convert the state of the checkboxes into a readable string
     const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const TNVbasicText= formatCheckboxStateToString(TNVbasic);
 
    // Prepare form details for summary
    const formDetails = {
      "Examen clinique hanche": formState.sideHip,
      "Etat cutané": [ formState.skinHip, formState.skin2Hip]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),
      
      "Status N-V": TNVbasicText,

      "Longueur membres inférieur": formState.legDisHip + (formState.quantLenghtHip ? `${formState.quantLenghtHip} mm` : ""),
      
      "Flexion/Extension": [formState.flexionHip, formState.neutralFEHip, formState.extensionHip]
        .filter(val => val !== "" && val !== null && val !== undefined)
        .join('/'),
      "Abduction/Adduction": [formState.abdHip, formState.neutralAAHip, formState.addHip]
        .filter(val => val !== "" && val !== null && val !== undefined)
        .join('/'),
      "Rotation externe/interne": [formState.reHip, formState.NeutralRotationHip, formState.riHip]
        .filter(val => val !== "" && val !== null && val !== undefined)
        .join('/'),
      
      "Signe de Lasègue":formState.lasegue,
      "Signe de Trendelburg": formState.trendelburgHip,
      "FADIR": formState.fadirHip,
      "FABER": formState.faberHip,
      "Drehmann": formState.drehmannHip,
      "Test de Thomas": formState.thomasHip
    };


    onFormSubmit(formDetails); // Call the function passed from App.js and pass the details


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object


    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details}


}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSetDefaultValues = () => {
    setFormState((prevState) => ({
      ...prevState,
      ...defaultValues
    }));
  };

  return (
    <div className="card">
      <p className="title">Examen clinique hanche</p>
      <form className="specific-form" onSubmit={handleSubmit}>
      <div>
        <label>Côté:  </label>
        <input type="radio" name="sideHip" value="gauche" checked={formState.sideHip === "gauche"} onChange={handleInputChange} />
        <label> gauche </label>
      
        <input type="radio" name="sideHip" value="droite" checked={formState.sideHip === "droite"} onChange={handleInputChange} />
        <label> droit </label>
      </div>

      <div>     
        <select name="legDisHip" className="select-input" value={formState.legDisHip} onChange={handleInputChange} >
          <option value="">longueur membres inférieurs:</option>
          <option value="symétrique">symétrique</option>
          <option value="gauche > droit ">gauche {'>'} droit</option>
          <option value="droit > gauche ">droit {'>'} gauche</option>
        </select>
      </div>

      {formState.legDisHip === "gauche > droit " && (
           <div>
           <label>Inégalité de longueur:</label>
           <input type="number" className="number-input" name="quantLenghtHip" min="0" max="500" placeholder = "mm" value={formState.quantLenghtHip} onChange={handleInputChange} required/>
         </div>
      )
      
      }

{formState.legDisHip === "droit > gauche " && (
           <div>
           <label>Inégalité de longueur:</label>
           <input type="number" className="number-input" name="quantLenghtHip" min="0" max="500" placeholder = "mm" value={formState.quantLenghtHip} onChange={handleInputChange} required/>
         </div>
      )
      
      }

      

    <label className="title-label-within-card">Etat cutané:</label>

      <div> 
      
        <select name="skinHip" className="select-input" value={formState.skinHip} onChange={handleInputChange}>
          <option value="pas de plaie, pas de cicatrice">Pas de plaie, pas de cicatrice</option>
          <option value="cicatrice d'abord antérieur">Cicactrice d'abord antérieur</option>
          <option value="cicatrice d'abord latéral">cicatrice d'abord latéral</option>
          <option value="cicatrice d'abord postérieur">cicatrice d'abord postérieur</option>
          <option value="AUTRE">AUTRE</option>
        </select>

      

      </div>

      {formState.skinHip !== "pas de plaie, pas de cicatrice" && (
           <div>
              <select name="skin2Hip" className="select-input" value={formState.skin2Hip} onChange={handleInputChange} >
          <option value="calme, sans signe infectieux">calme, sans signe infectieux</option>
          <option value="inflammatoire">inflammatoire</option>
          <option value="avec signes infectieux (écoulement, rougeur)">signes infectieux (écoulement, rougeur)</option>
          <option value="chéloïde">chéloïde</option>
          <option value="AUTRE">AUTRE</option>
        </select>
         
         </div>
      )
      
      }

<div className="checkbox-options">
        
            <input type="checkbox" name="absence de trouble neuro-vasculaire"  onChange={handleCheckboxChangeTNVbasic} />
            <label>absence de trouble neuro-vasculaire</label>
          
        
      </div>
  

<br></br>
  <label className="title-label-within-card">Amplitudes articulaire:</label>
  

  <div>
  <br></br>
   <button type="button" className="button-default-values" onClick={handleSetDefaultValues}>Partir des valeurs par défaut </button>
    
      <div>
      <br></br>
        <label>Flexion - Position neutre - Extension:</label>
        <input type="number" className="number-input" name="flexionHip" min="0" max="160" placeholder = "120" value={formState.flexionHip} onChange={handleInputChange} />

        <input type="number" className="number-input" name="neutralFEHip" min="0" max="160" placeholder = "0" alue={formState.neutralFEHip} onChange={handleInputChange} />
        
        <input type="number" className="number-input" name="extensionHip" min="0" max="160" placeholder = "20" value={formState.extensionHip} onChange={handleInputChange} />
      </div>
      </div>


   
     
      <div>
      
        <label>Abduction - Neutre - Adduction:</label>
        <input type="number" className="number-input" name="abdHip" min="0" max="160" placeholder = "45" value={formState.abdHip} onChange={handleInputChange} />
    
        <input type="number" className="number-input" name="neutralAAHip" min="0" max="160" placeholder = "0" value={formState.neutralAAHip} onChange={handleInputChange} />
  
        <input type="number" className="number-input" name="addHip" min="0" max="160" placeholder = "20" value={formState.addHip} onChange={handleInputChange} />
      </div>


      <div>
        <label>Rot. externe - Neutre - Rot. interne:</label>
        <input type="number" className="number-input" name="reHip" min="0" max="160" placeholder = "45" value={formState.reHip} onChange={handleInputChange} />
      
        <input type="number" className="number-input" name="NeutralRotationHip" min="0" max="160" placeholder = "0" value={formState.NeutralRotationHip} onChange={handleInputChange} />

        <input type="number" className="number-input" name="riHip" min="0" max="160" placeholder = "30" value={formState.riHip} onChange={handleInputChange} />
      </div>

     
      <div>
          <label>Lasègue: &emsp; &emsp;</label>
          
          <input type="radio" name="lasegue" value="négatif" checked={formState.lasegue === "négatif"} onChange={handleInputChange} />
            <label> - </label>
         
            <input type="radio" name="lasegue" value="positif" checked={formState.lasegue === "positif"} onChange={handleInputChange} />
            <label> + </label>
        </div>


      <div>
          <label>Trendelburg: &ensp; </label>
          
          <input type="radio" name="trendelburgHip" value="négatif" checked={formState.trendelburgHip === "négatif"} onChange={handleInputChange} />
            <label> - </label>
         
            <input type="radio" name="trendelburgHip" value="positif" checked={formState.trendelburgHip === "positif"} onChange={handleInputChange} />
            <label> + </label>
        </div>


        <div>
          <label>FADIR:  &emsp; &emsp; </label>
         
          <input type="radio" name="fadirHip" value="négatif" checked={formState.fadirHip === "négatif"} onChange={handleInputChange} />
            <label> - </label>
         
            <input type="radio" name="fadirHip" value="positif" checked={formState.fadirHip === "positif"} onChange={handleInputChange} />
            <label> + </label>
        </div>

        <div>
          <label>FABER:  &emsp; &emsp;</label>
          
          <input type="radio" name="faberHip" value="négatif" checked={formState.faberHip === "négatif"} onChange={handleInputChange} />
            <label> - </label>
         
            <input type="radio" name="faberHip" value="positif" checked={formState.faberHip === "positif"} onChange={handleInputChange} />
            <label> + </label>
        </div>

        <div>
          <label>Drehmann:  &emsp;</label>
         
          <input type="radio" name="drehmannHip" value="négatif" checked={formState.drehmannHip === "négatif"} onChange={handleInputChange} />
            <label> - </label>
         
            <input type="radio" name="drehmannHip" value="positif" checked={formState.drehmannHip === "positif"} onChange={handleInputChange} />
            <label> + </label>
        </div>

        <div>
          <label>Thomas test:  &ensp;</label>
        
          <input type="radio" name="thomasHip" value="négatif" checked={formState.thomasHip === "négatif"} onChange={handleInputChange} />
            <label> - </label>
         
            <input type="radio" name="thomasHip" value="positif" checked={formState.thomasHip === "positif"} onChange={handleInputChange} />
            <label> + </label>
        </div>




        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default Hip;