// Allergies.js
import React, { useEffect, useState } from 'react';
import '../../../styles/app.css';

function HarrisHipClinicalScore({ setNameForm, onFormSubmit }) {
  const [formState, setFormState] = useState({
    SideHarrisHipClinicalScore:'',
    Q1_HipHistory: '',
    Q2_HipHistory: '',
    Q3_HipHistory: '',
    Q4_HipHistory: '',
    Q5_HipHistory: '',
    Q6_HipHistory: '',
    Q7_HipHistory: '',
    Q8_HipHistory: '',
    Q9_HipHistory: '',
    Q10_HipHistory: '',
    Q11_HipHistory: '',
    Q12_HipHistory: '',
  });

  



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const [HarrisHipScore, setHarrisHipScore] = useState(null);

  useEffect(() => {
    if (formState.Q5_HipHistory && formState.Q6_HipHistory &&
      formState.Q7_HipHistory && formState.Q8_HipHistory &&
      formState.Q9_HipHistory && formState.Q10_HipHistory &&
      formState.Q11_HipHistory && formState.Q12_HipHistory

    ) {
      
      var HarrisHipScoreValue = 0;
     
      if (formState.Q5_HipHistory === "Ces douleurs n'ont pas de répercussion fonctionnelle, pas de limitation des AVQ ou des activités annexes") {HarrisHipScoreValue += 44;}
      if (formState.Q5_HipHistory === "Ces douleurs ont une répercussion fonctionnelle légère: pas de limitation des AVQ, limitation occasionnelle des activité annexes") {HarrisHipScoreValue += 40;}
      if (formState.Q5_HipHistory === "Ces douleurs ont une répercussion fonctionnelle légère: pas de limitation des AVQ mais limitation des activités annexes") {HarrisHipScoreValue += 30;}
      if (formState.Q5_HipHistory === "Ces douleurs ont une répercussion fonctionnelle modérée, avec limitation partielle des AVQ") {HarrisHipScoreValue += 20;}
      if (formState.Q5_HipHistory === "Ces douleurs ont une répercussion fonctionnelle importante, avec forte limitation des AVQ") {HarrisHipScoreValue += 10;}
      if (formState.Q5_HipHistory === "Ces douleurs ont une répercussion fonctionnelle très importante, situation de handicap") {HarrisHipScoreValue += 0;}

      if (formState.Q6_HipHistory === "La marche s'effectue sans moyen auxiliaire") {HarrisHipScoreValue += 11;}
      if (formState.Q6_HipHistory === "La marche s'effectue à l'aide d'une canne ou d'un baton de marche pour les marches prolongées (pas de moyen auxiliaire pour courtes distances)") {HarrisHipScoreValue += 7;}
      if (formState.Q6_HipHistorye === "La marche s'effectue la plupart du temps à l'aide d'une canne ou d'un baton de marche") {HarrisHipScoreValue += 5;}
      if (formState.Q6_HipHistory === "La marche s'effectue à l'aide d'une une canne anglaise") {HarrisHipScoreValue += 3;}
      if (formState.Q6_HipHistory === "La marche s'effectue à l'aide de deux cannes ou deux baton de marche") {HarrisHipScoreValue += 2;}
      if (formState.Q6_HipHistory === "La marche s'effectue à l'aide de deux cannes anglaises (ou marche impossible)") {HarrisHipScoreValue += 0;}

 
     
      if (formState.Q7_HipHistory === "Le périmètre de marche n'est pas limité") {HarrisHipScoreValue += 11;}
      if (formState.Q7_HipHistory === "Le périmètre de marche est limité à 30 minutes / 500 mètres") {HarrisHipScoreValue += 8;}
      if (formState.Q7_HipHistory === "Le périmètre de marche est limité à 15 minutes / 250 mètres") {HarrisHipScoreValue += 5;}
      if (formState.Q7_HipHistory === "Le périmètre de marche est limité à l'intérieur du domicile") {HarrisHipScoreValue += 2;}
      if (formState.Q7_HipHistory === "La marche est impossible, transfert uniquement") {HarrisHipScoreValue += 0;}

      if (formState.Q8_HipHistory === "Absence de boiterie") {HarrisHipScoreValue += 11;}
      if (formState.Q8_HipHistory === "Boiterie légère") {HarrisHipScoreValue +=8;}
      if (formState.Q8_HipHistory === "Boiterie modérée") {HarrisHipScoreValue +=5;}
      if (formState.Q8_HipHistory === "Boiterie importante, ou impossibilité de marcher") {HarrisHipScoreValue += 0;}
      


      if (formState.Q9_HipHistory === "L'utilisation des escaliers n'est pas limitée") {HarrisHipScoreValue += 4;}
      if (formState.Q9_HipHistory === "L'utilisation des escaliers nécessite une rambarde") {HarrisHipScoreValue += 2;}
      if (formState.Q9_HipHistory === "L'utilisation des escaliers est difficile mais possible") {HarrisHipScoreValue += 1;}
      if (formState.Q9_HipHistory === "L'utilisation des escaliers est impossible") {HarrisHipScoreValue += 0;}

      if (formState.Q10_HipHistory === "La position assise est confortable, non limitée") {HarrisHipScoreValue += 5;}
      if (formState.Q10_HipHistory === "La position assise est est limitée à 30 min ou nécessite une chaise haute") {HarrisHipScoreValue += 3;}
      if (formState.Q10_HipHistory === "La position assise est incomfortable") {HarrisHipScoreValue += 0;}
    
 

      if (formState.Q11_HipHistory === "L'accès au pied est non limité") {HarrisHipScoreValue += 4;}
      if (formState.Q11_HipHistory === "L'accès au pied est difficile") {HarrisHipScoreValue += 2;}
      if (formState.Q11_HipHistory === "L'accès au pied est impossible") {HarrisHipScoreValue += 0;}
     

 
     
      if (formState.Q12_HipHistory === "L'utilisation des transports publics est possible") {HarrisHipScoreValue += 1;}
      if (formState.Q12_HipHistory === "L'utilisation des transports publics est impossible") {HarrisHipScoreValue += 0;}
     
    
     
      setHarrisHipScore(HarrisHipScoreValue);
     } 

     
     else {
       setHarrisHipScore("");
     }
 }, [
  formState.Q5_HipHistory, formState.Q6_HipHistory,
  formState.Q7_HipHistory, formState.Q8_HipHistory,
  formState.Q9_HipHistory, formState.Q10_HipHistory,
  formState.Q11_HipHistory, formState.Q12_HipHistory]);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    setNameForm('Harris Hip Score, anamnèse');


    const formDetails = {
    'HarrisHipClinicalScore': "Questions spécifiques au score de Harris, partie anamnestique, hanche " + formState.SideHarrisHipClinicalScore + ":",

    'L1': "A l'anamnèse, on retrouve des douleurs à caractère " + formState.Q1_HipHistory + " localisées " + formState.Q2_HipHistory + ", " + formState.Q3_HipHistory,
    'L2': "EVA estimée à " + formState.Q4_HipHistory,
    
    'L3': [formState.Q5_HipHistory, formState.Q6_HipHistory,formState.Q6_HipHistory,formState.Q7_HipHistory,formState.Q8_HipHistory,formState.Q9_HipHistory,formState.Q10_HipHistory,formState.Q11_HipHistory, formState.Q12_HipHistory]
    .filter(val => val !== "" && val !== null && val !== undefined)
    .join('. '),

  
    
    'score value': "Score de Harris calculé (partie anamnestique): " + HarrisHipScore + "/91"
     
      
    };

    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  return (
    <div className="card">
      <p className="title">Harris Hip score, partie anamnèse</p>
      <form className="specific-form" onSubmit={handleSubmit}>


      <div className="checkbox-options">
    
        <div>
        <input type="radio" name="SideHarrisHipClinicalScore" value="gauche" checked={formState.SideHarrisHipClinicalScore === "gauche"} onChange={handleInputChange} />
        <label> hanche gauche </label>

        <input type="radio" name="SideHarrisHipClinicalScore" value="droit" checked={formState.SideHarrisHipClinicalScore === "droit"} onChange={handleInputChange} />
        <label> hanche droite </label>
        </div>
        </div>


      <br></br>

        <div>
        
          <select name="Q1_HipHistory" className="select-input" value={formState.Q1_HipHistory} onChange={handleInputChange} required >
            <option value="">Type de douleurs</option>
            <option value="mécaniques">mécaniques</option>
            <option value="inflammatoires">inflammatoires</option>
            <option value="mixtes">mixtes</option>
            <option value="AUTRE">AUTRE</option>
          
          </select>
       
       
          <select name="Q2_HipHistory" className="select-input" value={formState.Q2_HipHistory} onChange={handleInputChange} required >
            <option value="">Localisation douleurs</option>
            <option value="au pli de l'aine">pli de l'aine</option>
            <option value="latéralement">latérale</option>
            <option value="postérieurement">postérieure</option>
            <option value="en hémi-ceinture">hémi-ceinture</option>
            <option value="AUTRE">AUTRE</option>
          </select>
        
      
          <select name="Q3_HipHistory" className="select-input" value={formState.Q3_HipHistory} onChange={handleInputChange} required >
          <option value="">Irradiation:</option>
            <option value="sans irradiation">aucune</option>
            <option value="avec une irradiation antérieure">antérieure</option>
            <option value="avec une irradiation postérieure">postérieure</option>
            <option value="avec irradiation AUTRE">AUTRE</option>
           
          </select>
       
          <select name="Q4_HipHistory" className="select-input" value={formState.Q4_HipHistory} onChange={handleInputChange} required >
          <option value="">EVA</option>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
        
          </select>
        
        
          <select name="Q5_HipHistory" className="select-input" value={formState.Q5_HipHistory} onChange={handleInputChange} required >
          <option value="">Répercussion fonctionnelle:</option>
            <option value="Ces douleurs n'ont pas de répercussion fonctionnelle, pas de limitation des AVQ ou des activités annexes">aucune limitation</option>
            <option value="Ces douleurs ont une répercussion fonctionnelle légère: pas de limitation des AVQ, limitation occasionnelle des activité annexes">douleurs légères, pas de limitation AVQ, limitation occasionnelle des activités annexes</option>
            <option value="Ces douleurs ont une répercussion fonctionnelle légère: pas de limitation des AVQ mais limitation des activités annexes">douleurs légères, pas de limitation AVQ, mais limitation activités annexes</option>
            <option value="Ces douleurs ont une répercussion fonctionnelle modérée, avec limitation partielle des AVQ">douleurs modérées, limitations partielle des AVQ</option>
            <option value="Ces douleurs ont une répercussion fonctionnelle importante, avec forte limitation des AVQ">Douleurs importantes, forte limitation des AVQ</option>
            <option value="Ces douleurs ont une répercussion fonctionnelle très importante, situation de handicap">Douleurs invalidantes, handicap</option>
          </select>
       
       
          <select name="Q6_HipHistory" className="select-input" value={formState.Q6_HipHistory} onChange={handleInputChange} required >
          <option value="">Moyen auxiliaire:</option>
            <option value="La marche s'effectue sans moyen auxiliaire">aucun</option>
            <option value="La marche s'effectue à l'aide d'une canne ou d'un baton de marche pour les marches prolongées (pas de moyen auxiliaire pour courtes distances)">canne ou baton de marche pour les marches prolongées</option>
            <option value="La marche s'effectue la plupart du temps à l'aide d'une canne ou d'un baton de marche">canne ou baton de marche la plupart du temps</option>
            <option value="La marche s'effectue à l'aide d'une une canne anglaise">une canne anglaise</option>
            <option value="La marche s'effectue à l'aide de deux cannes ou deux baton de marche">deux cannes ou deux baton de marche</option>
            <option value="La marche s'effectue à l'aide de deux cannes anglaises (ou marche impossible)">deux cannes anglaises / impossibilité de marcher</option>
          </select>
        
          <select name="Q7_HipHistory" className="select-input" value={formState.Q7_HipHistory} onChange={handleInputChange} required >
          <option value="">Périmètre de marche:</option>
            <option value="Le périmètre de marche n'est pas limité">Non limité</option>
            <option value="Le périmètre de marche est limité à 30 minutes / 500 mètres">30min / 500m</option>
            <option value="Le périmètre de marche est limité à 15 minutes / 250 mètres">15 min / 250m</option>
            <option value="Le périmètre de marche est limité à l'intérieur du domicile">à l'intérieur uniquement</option>
            <option value="La marche est impossible, transfert uniquement">transferts uniquement</option>
          </select>
        
      
          <select name="Q8_HipHistory" className="select-input" value={formState.Q8_HipHistory} onChange={handleInputChange} required >
          <option value="">Boiterie:</option>
            <option value="Absence de boiterie">aucune</option>
            <option value="Boiterie légère">légère</option>
            <option value="Boiterie modérée">modérée</option>
            <option value="Boiterie importante, ou impossibilité de marcher">importante, ou impossibilité de marcher</option>
    
          </select>
        
        
          <select name="Q9_HipHistory" className="select-input" value={formState.Q9_HipHistory} onChange={handleInputChange} required >
          <option value="">Utilisation escaliers:</option>
            <option value="L'utilisation des escaliers n'est pas limitée">sans limitation</option>
            <option value="L'utilisation des escaliers nécessite une rambarde">nécessite une rambarde</option>
            <option value="L'utilisation des escaliers est difficile mais possible">difficile mais possible</option>
            <option value="L'utilisation des escaliers est impossible">impossible</option>
            
          </select>
        
        
          <select name="Q10_HipHistory" className="select-input" value={formState.Q10_HipHistory} onChange={handleInputChange} required >
          <option value="">Position assise:</option>
            <option value="La position assise est confortable, non limitée">confortable, position assise possible plus d'une heure</option>
            <option value="La position assise est est limitée à 30 min ou nécessite une chaise haute">inconfortable, chaise haute ou limitée à 30min</option>
            <option value="La position assise est incomfortable">impossibilité de s'assoir confortablement</option>
       
          </select>
       
    
          <select name="Q11_HipHistory" className="select-input" value={formState.Q11_HipHistory} onChange={handleInputChange} required >
          <option value="">Accès au pied:</option>
            <option value="L'accès au pied est non limité">non limité</option>
            <option value="L'accès au pied est difficile">difficile</option>
            <option value="L'accès au pied est impossible">impossible</option>
       
          </select>
         
        

       
          <select name="Q12_HipHistory" className="select-input" value={formState.Q12_HipHistory} onChange={handleInputChange} required >
          <option value="">Utilisation transports publics</option>
            <option value="L'utilisation des transports publics est possible">Possible</option>
            <option value="L'utilisation des transports publics est impossible">Impossible</option>
  
          </select>
        
        </div>

      
        <div>
        <label>Harris Score, partie anamnestique: </label>
       {HarrisHipScore && (
            
              <input type="text" className="small-input" value={HarrisHipScore} readOnly />
            
          )}

      </div>
     
      <label className = "indication-italic"> Pour le score de Harris complet: comptabiliser les 9 points possibles de l'examen clinique</label> <br></br>
        

        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default HarrisHipClinicalScore;