import React, { useState } from "react";
import "../../../styles/app.css";

function Wrist({setNameForm, onFormSubmit}) { 
  const [formState, setFormState] = useState({
    side: "",
    Skin: "pas de plaie, pas de cicatrice",
    Scar: "",
    Flexion: "",
    NeutralFE: "",
    Extension: "",
    RadialInclination:"",
    NeutralInclination:"",
    UlnarInclination:""

  });

  const defaultValues = {
    Flexion: 60,
    NeutralFE: 0,
    Extension: 60,
    RadialInclination: 20,
    NeutralInclination: 0,
    UlnarInclination:20
  };


  const [WristPalpation, setWristPalpation] = useState({
  
    "styloïde radiale": false,
    "styloïde ulnaire": false,
    "interligne radio-carpien dorsal": false,
    "interligne radio-carpien palmaire": false,
    "AUTRE": false
  
  });


  const handleCheckboxChangeWristPalpation = (event) => {
    const { name, checked } = event.target;
     
      setWristPalpation(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };


  const [TNVbasic, setTNVbasic] = useState({
    "absence de trouble neuro-vasculaire": false,
  });


  const handleCheckboxChangeTNVbasic = (event) => {
    const { name, checked } = event.target;
     
      setTNVbasic(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Examen clinique poignet");
 // Function to convert the state of the checkboxes into a readable string
    const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const WristPalpationText = formatCheckboxStateToString(WristPalpation);
  const TNVbasicText= formatCheckboxStateToString(TNVbasic);


    // Prepare form details for summary
    const formDetails = {
      "Examen clinique poignet": formState.side,
      "Etat cutané": [ formState.Skin, formState.Scar]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),


      "Status N-V": TNVbasicText,
      "Site(s) douloureux à la palpation": WristPalpationText,

      "Flexion/Extension": [formState.Flexion, formState.NeutralFE, formState.Extension]
        .filter((val) => val !== "" && val !== null && val !== undefined)
        .join('/'),

      "Inclinaison radiale/ulnaire": [formState.RadialInclination, formState.NeutralInclination, formState.UlnarInclination]
      .filter((val) => val !== "" && val !== null && val !== undefined)
      .join('/'),

      
    };

    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details


   
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };


  const handleSetDefaultValues = () => {
    setFormState((prevState) => ({
      ...prevState,
      ...defaultValues
    }));
  };


  return (
    <div className="card">
      <p className="title">Examen clinique poignet</p>
      <form className="specific-form" onSubmit={handleSubmit}>
      <div>
          <label>Côté:  </label>
          <input type="radio" name="side" value="gauche" checked={formState.side === "gauche"} onChange={handleInputChange} />
            <label> gauche </label>
         
            <input type="radio" name="side" value="droit" checked={formState.side === "droit"} onChange={handleInputChange} />
            <label> droit </label>
        </div>

        <div> 

        <label>Etat cutané:</label>
          <select name="Skin" className="select-input" value={formState.Skin} onChange={handleInputChange} >
            <option value="pas de plaie, pas de cicatrice">Pas de plaie, pas de cicatrice</option>
            <option value="cicatrice d'abord palmaire">Cicactrice d'abord palmaire</option>
            <option value="cicatrice d'abord dorsal">cicatrice d'abord dorsal</option>
            <option value="AUTRE">AUTRE</option>
          </select>

          {formState.Skin !== "pas de plaie, pas de cicatrice" && (
           <div>
             <select name="Scar" className="select-input" value={formState.Scar} onChange={handleInputChange} >
            <option value="calme, sans signe infectieux">calme, sans signe infectieux</option>
            <option value="inflammatoire">inflammatoire</option>
            <option value="avec signes infectieux (écoulement, rougeur)">signes infectieux (écoulement, rougeur)</option>
            <option value="chéloïde">chéloïde</option>
            <option value="AUTRE">AUTRE</option>
          </select>
         
         </div>
      )
    }

        </div>

        



        <div className="checkbox-options">
                
                <input type="checkbox" name="absence de trouble neuro-vasculaire"  onChange={handleCheckboxChangeTNVbasic} />
                <label>absence de trouble neuro-vasculaire</label>
              
          </div>



      

        <div className="checkbox-options">
        <p className="title-label-within-card">Douleurs à la palpation:</p>
        {Object.entries(WristPalpation).map(([key, value]) => (
          <div key={key}>
            <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeWristPalpation} />
            <label>{key}</label>
          </div>
        ))}
      </div>

      <br></br>

      <div>
        
      
        <label className="title-label-within-card">Amplitudes articulaire: <br></br></label>
          <br></br>
          <div>
           <button type="button" className="button-default-values" onClick={handleSetDefaultValues}>Partir des valeurs par défaut </button>
           </div>
           
        </div> 

      <div>
          <label>Flexion/extension:</label><br></br>
          <input type="number" className="number-input" name="Flexion"  value={formState.Flexion} onChange={handleInputChange} />
        
          <input type="number" className="number-input" name="NeutralFE"  value={formState.NeutralFE} onChange={handleInputChange} />
       
          <input type="number" className="number-input" name="Extension"  value={formState.Extension} onChange={handleInputChange} />
        </div>

        <div>
          <label>Inclinaison radiale/neutre/ulnaire:</label><br></br>
          <input type="number" className="number-input" name="RadialInclination"  value={formState.RadialInclination} onChange={handleInputChange} />
        
          <input type="number" className="number-input" name="NeutralInclination"  value={formState.NeutralInclination} onChange={handleInputChange} />
       
          <input type="number" className="number-input" name="UlnarInclination"  value={formState.UlnarInclination} onChange={handleInputChange} />
        </div>


  



        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default Wrist;