import React from "react";
import "../../styles/app.css";

function CheckboxXRays({ selectedOptions, onSelectedOptionsChange, handleButtonClick, showSpecificForm }) {
  
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    // We only update the local state to reflect the UI.
    onSelectedOptionsChange({ ...selectedOptions, [name]: checked });
  };

  const isChecked = Object.values(selectedOptions).some(value => value);

  return (
    <div className="card">
      <p className="title">Options bilan radiologique</p>
      <div className="checkbox-options">
        {Object.keys(selectedOptions).map((key) => (
          <div key={key}>
            <input
              type="checkbox"
              id={`checkbox-${key}`}
              name={key}
              checked={selectedOptions[key]}
              onChange={handleCheckboxChange}
            />
            <label htmlFor={`checkbox-${key}`}>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
          </div>
        ))}
      </div>
      <button
        onClick={handleButtonClick}
        className={isChecked ? (showSpecificForm ? "button selected" : "button enabled") : "button disabled"}
        disabled={!isChecked}
      >
        {showSpecificForm ? "Unselect" : "Select"}
      </button>
    </div>
  );
}

export default CheckboxXRays;