import React, { useState } from "react";
import "../../../styles/app.css";
import Modal from "../../../other-components/Modal.js";

function CollarBone({setNameForm, onFormSubmit}) {


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [specificImage, setSpecificImage] = useState("");

  const openModalWithSpecificImage = (image) => {
    setSpecificImage(image);
    setIsModalOpen(true);
  }

  const [formState, setFormState] = useState({
    xRayWorkUp: '',
    side: '',
    DiaphysealFracture: '',
    DistalFracture:'',
    AClesion:''

  });

  const [XRayWorkUp, setXRayWorkUp] = useState({
   
    "clavicule face": false,
    "clavicule tangentielle": false,
    "épaule face": false,
    "épaule profil": false,
    "Zanca": false,
    "CT-scan": false,
  
  });

  const handleCheckboxChangeXRayWorkUp = (event) => {
    const { name, checked } = event.target;
     
      setXRayWorkUp(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };


  const [showXray, setshowXray] = useState(false);

  const handleChangeshowXray = (event) => {
    setshowXray(event.target.checked);
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Coude & avant-bras");
 // Function to convert the state of the checkboxes into a readable string
    const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const XRayWorkUpText = formatCheckboxStateToString(XRayWorkUp);
  


    // Prepare form details for summary
    const formDetails = {
      "Côté": formState.side,
      "Bilan radiologique": XRayWorkUpText,
      "Clavicule, 1/3 moyen": formState.DiaphysealFracture,
      "Clavicule, 1/3 distal": formState.DistalFracture,
      "Articulation acromio-claviculaire": formState.AClesion,
      
  
    };


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

   


    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };






  return (
    <div className="card">
      <p className="title">Clavicule, bilan radiologique</p>
      <form className="specific-form" onSubmit={handleSubmit}>



      <div className="checkbox-options">
            <div>
             <input type="radio" name="side" value="gauche" checked={formState.side === "gauche"} onChange={handleInputChange} />
            <label> gauche </label>
         
            <input type="radio" name="side" value="droit" checked={formState.side === "droit"} onChange={handleInputChange} />
            <label> droit </label>
            </div>
        </div>

      <div>

      <br></br>
   
   <input
       type="checkbox"
       name="showXray"
       onChange={handleChangeshowXray}
       
     />
     <label>Renseigner incidences radiologiques</label>

   </div>
   

   {showXray && (
       <div className="checkbox-options">
       
       {Object.entries(XRayWorkUp).map(([key, value]) => (
         <div key={key}>
           <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeXRayWorkUp} />
           <label>{key}</label>
         </div>
       ))}
     </div>

   )}
 
   

      <br></br>

        <div>
        
          <select name="DiaphysealFracture" className="select-input" value={formState.DiaphysealFracture} onChange={handleInputChange} >
            <option value="">fracture médio-claviculaire, AO:</option>
            <option value="fracture spiroide (Classification AO 15-A1)">spiroide (Classification AO 15-A1)</option>
            <option value="fracture oblique (Classification AO 15-A2)">oblique (Classification AO 15-A2)</option>
            <option value="fracture transverse (Classification AO 15-A3)">transverse (Classification AO 15-A3)</option>
            <option value="fracture type wedge (Classification AO 15-B2)">type wedge (Classification AO 15-B2)</option>
            <option value="fracture type wedge, fragmentaire (Classification AO 15-B3)">type wedge, fragmentaire (Classification AO 15-B3)</option>
            <option value="fracture segmentaire (Classification AO 15-C2)">segmentaire (Classification AO 15-C2)</option>
            <option value="fracture segmentaire, plurifragmentaire (Classification AO 15-C3)">segmentaire, plurifragmentaire (Classification AO 15-C3)</option>
           
          </select>
      
    
          <select name="DistalFracture" className="select-input" value={formState.DistalFracture} onChange={handleInputChange} >
            <option value="">Lésion clavicule distale:</option>
            <option value="fracture extra-articulaire, latérale aux ligaments CC, Neer I">extra-articulaire, latérale aux ligaments CC, Neer I</option>
            <option value="fracture médiale aux ligaments CC, Neer IIa">médiale aux ligaments CC, Neer IIa</option>
            <option value="au niveau ligaments CC, ou latéralement et associée à lésion CC, Neer IIb">au niveau ligaments CC, ou latéralement et associée à lésion CC, Neer IIb</option>
            <option value="fracture latérale aux ligaments CC, intra-articulaire, Neer III">latérale aux ligaments CC, intra-articulaire, Neer III</option>
            <option value="fractures au niveau ligne épiphysaire, Neer IV">au niveau ligne épiphysaire, Neer IV</option>
            <option value="fracture comminutive, ligaments CC intacts, Neer V">comminutive, ligaments CC intacts, Neer V</option>

            </select>
            

          <select name="AClesion" className="select-input" value={formState.AClesion} onChange={handleInputChange} >
            <option value="">Articulation acromio-claviculaire:</option>
            <option value="pas de lésion radiologique, Tossy-Rockwood I (entorse AC)">pas de lésion radiologique, Tossy-Rockwood I {'('}entorse AC{')'}</option>
            <option value="ouverture AC (>7mm), élevation clavicule (<25%), Tossy-Rockwood II (rupture AC, entorse CC)">ouverture AC {'(>'}7mm{')'}, élevation clavicule {'(<'}25%{')'}, Tossy-Rockwood II {'('}rupture AC, entorse CC{')'}</option>
            <option value="élevation clavicule cranialement acromion, distance CC <25mm, Tossy-Rockwood III (rupture AC, rupture CC)">élevation clavicule cranialement acromion, distance CC {'<'}25mm, Tossy-Rockwood III {'('}rupture AC, rupture CC{')'}</option>
            <option value="élevation et déplacement postérieur calvicule, Tossy-Rockwood IV (rupture AC, rupture CC, clavicule au travers trapèze)">élevation et déplacement postérieur calvicule, Tossy-Rockwood IV {'('}rupture AC, rupture CC, clavicule au travers trapèze{')'}</option>
            <option value="élevation calvicule, distance CC >25mm, Tossy-Rockwood V (rupture AC, rupture CC, lésion musculaire)">élevation calvicule, distance CC {'>'}25mm, Tossy-Rockwood V {'('}rupture AC, rupture CC, lésion musculaire{')'}</option>
            <option value="Neer VI: légende urbaine">Neer VI: légende urbaine</option>
            </select>

   
        </div>

       
      
        <button type="submit" className="button send">Send</button>
      </form>


      <br></br>

     
    
    </div>
  );
}

export default CollarBone;