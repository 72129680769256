import React, { useState, useEffect } from "react";
import "../styles/app.css"; 
import InitialCard from "./lowerLimb-initial-cards/InitialCard";
import Summary from "../other-components/Summary";
import TextSummary from "../other-components/TextSummary";

/*_____________________CHECKBOX CARDS_____________________*/
import CheckboxPhysicalExam from "./lowerLimb-checkbox-cards/CheckboxPhysicalExam";
import CheckboxMedicalHistory from "./lowerLimb-checkbox-cards/CheckboxMedicalHistory";
import CheckboxXRays from "./lowerLimb-checkbox-cards/CheckboxXRays";
/*________________________________________________________*/

import Hip from "./lowerLimb-specific-cards/physical-exam/Hip";
import Knee from "./lowerLimb-specific-cards/physical-exam/Knee";
import NVstatusMI from "./lowerLimb-specific-cards/physical-exam/NVstatusMI";
import GeneralPhysicalExam from "./lowerLimb-specific-cards/physical-exam/GeneralPhysicalExam";
import Ankle from "./lowerLimb-specific-cards/physical-exam/Ankle";

import MonoTraumaHistory from "./lowerLimb-specific-cards/medical-history/MonoTraumaHistory";
import ChronicConditions from "./lowerLimb-specific-cards/medical-history/ChronicConditions";
import OxfordKneeScore from "./lowerLimb-specific-cards/medical-history/OxfordKneeScore";
import HarrisHipClinicalScore from "./lowerLimb-specific-cards/medical-history/HarrisHipScore";
import MedicalTreatment from "./lowerLimb-specific-cards/medical-history/MedicalTreatment";

import PelvisXRayTraumat from "./lowerLimb-specific-cards/x-rays/PelvisXRayTraumat";
import FemurXRayTraumat from "./lowerLimb-specific-cards/x-rays/FemurXRayTraumat";
import TibiaXRayTraumat from "./lowerLimb-specific-cards/x-rays/TibiaXRayTraumat";
import HipXray from "./lowerLimb-specific-cards/x-rays/HipXray";
import HipRecon from "./lowerLimb-specific-cards/x-rays/HipRecon";
import KneeXray from "./lowerLimb-specific-cards/x-rays/KneeXray";
import KneeRecon from "./lowerLimb-specific-cards/x-rays/KneeRecon";
import FullLowerLimbXray from "./lowerLimb-specific-cards/x-rays/FullLowerLimbXray";

/*______________________INITIAL CARDS______________________*/
const description1 = "Anamnèses orthopédie et traumatologie, membre inférieur";
const description2 = "Examens cliniques standardisés, membre inférieur";
const description3 = "Bilan radiologique orthopédie et traumatologie, membre inférieur";

const name1 = "Anamnèse";
const name2 = "Examen clinique";
const name3 = "Radiologie";

const image1 = "../medical-history.png";
const image2 = "../physical-exam.png";
const image3 = "../x-rays.png";
/*_______________________________________________________________________________________________*/

function LowerLimb() {
  const [formDetails, setFormDetails] = useState(null);
  const [nameForm, setNameForm] = useState("");
  const [isFormSended, setIsFormSended] = useState(false);
  const [isText, setIsText] = useState(false);
/*_______________________________________________________________________________________________*/
  /*PHYSICAL EXAM BOX*/

  const [isVisibleCheckboxPhysical, setIsVisibleCheckboxPhysical] = useState(false);
  const [showSpecificPhysicalForm, setShowSpecificPhysicalForm] = useState(false);
  const [selectedPhysicalOptions, setSelectedPhysicalOptions] = useState({
    Généralités: false,
    "Status neuro-vasculaire": false,
    Hanche: false,
    Genou: false,
    Cheville: false,
  });

  const handleSelectedPhysicalChange = (options) => {
    setSelectedPhysicalOptions(options);
  };

  const toggleVisiblePhysicalForm = () => {
    const anySelected = Object.values(selectedPhysicalOptions).some((value) => value);
    setShowSpecificPhysicalForm(anySelected);
  };

  useEffect(() => {
    if (!isVisibleCheckboxPhysical) {
      setSelectedPhysicalOptions({
        Généralités: false,
        "Status neuro-vasculaire": false,
        Hanche: false,
        Genou: false,
        Cheville: false,
      });
    }
  }, [isVisibleCheckboxPhysical]);

  useEffect(() => {
    const anySelected = Object.values(selectedPhysicalOptions).some((value) => value);
    if (!anySelected) {
      setShowSpecificPhysicalForm(false);
    }
  }, [selectedPhysicalOptions]);

/*_______________________________________________________________________________________________*/
  /*MEDICAL HISTORY*/

  const [isVisibleCheckboxHistory, setIsVisibleCheckboxHistory] = useState(false);
  const [showSpecificHistoryForm, setShowSpecificHistoryForm] = useState(false);
  const [selectedHistoryOptions, setSelectedHistoryOptions] = useState({
    "Anamnèse mono-traumatisme": false,
    "Genou: Oxford Knee Score": false,
    "Hanche: Harris score (anamnèse)": false,
    "Co-morbidités - en construction": false,
    "Traitement en cours - en construction": false,
  });

  const handleSelectedHistoryChange = (options) => {
    setSelectedHistoryOptions(options);
  };

  const toggleVisibleHistoryForm = () => {
    const anySelected = Object.values(selectedHistoryOptions).some((value) => value);
    setShowSpecificHistoryForm(anySelected);
  };

  useEffect(() => {
    if (!isVisibleCheckboxHistory) {
      setSelectedHistoryOptions({
        "Anamnèse mono-traumatisme": false,
        "Genou: Oxford Knee Score": false,
        "Hanche: Harris score (anamnèse)": false,
        "Co-morbidités - en construction": false,
        "Traitement en cours - en construction": false,
      });
    }
  }, [isVisibleCheckboxHistory]);

  useEffect(() => {
    const anySelected = Object.values(selectedHistoryOptions).some((value) => value);
    if (!anySelected) {
      setShowSpecificHistoryForm(false);
    }
  }, [selectedHistoryOptions]);

/*_______________________________________________________________________________________________*/
  /*X-RAYS*/

  const [isVisibleCheckboxXRays, setIsVisibleCheckboxXRays] = useState(false);
  const [showSpecificXRaysForm, setShowSpecificXRaysForm] = useState(false);
  const [selectedXRaysyOptions, setSelectedXRaysOptions] = useState({
    "Pelvis, traumatologie": false,
    "Fémur, traumatologie": false,
    "Tibia, péroné & rotule, traumatologie": false,
    "Hanche native": false,
    "Hanche prothétique": false,
    "Genou natif": false,
    "Genou prothétique": false,
    "Alignement membres inférieurs": false,
  });

  const handleSelectedXRaysChange = (options) => {
    setSelectedXRaysOptions(options);
  };

  const toggleVisibleXRaysForm = () => {
    const anySelected = Object.values(selectedXRaysyOptions).some((value) => value);
    setShowSpecificXRaysForm(anySelected);
  };

  useEffect(() => {
    if (!isVisibleCheckboxXRays) {
      setSelectedXRaysOptions({
        "Pelvis, traumatologie": false,
        "Fémur, traumatologie": false,
        "Tibia, péroné & rotule, traumatologie": false,
        "Hanche native": false,
        "Hanche prothétique": false,
        "Genou natif": false,
        "Genou prothétique": false,
        "Alignement membres inférieurs": false,
      });
    }
  }, [isVisibleCheckboxXRays]);

  useEffect(() => {
    const anySelected = Object.values(selectedXRaysyOptions).some((value) => value);
    if (!anySelected) {
      setShowSpecificXRaysForm(false);
    }
  }, [selectedXRaysyOptions]);

/*_______________________________________________________________________________________________*/

  const handleFormSubmit = (details, name, text) => {
    setFormDetails(details);
    setNameForm(name);
    setIsFormSended(true);
    setIsText(text);
  };

  return (
    <>
      {!isFormSended && (
        <div className="container">
          <div className="first-cards">
            <InitialCard
              name={name1}
              description={description1}
              image={image1}
              visible={isVisibleCheckboxHistory}
              setIsVisible={setIsVisibleCheckboxHistory}
            />
            <InitialCard
              name={name2}
              description={description2}
              image={image2}
              visible={isVisibleCheckboxPhysical}
              setIsVisible={setIsVisibleCheckboxPhysical}
            />
            <InitialCard
              name={name3}
              description={description3}
              image={image3}
              visible={isVisibleCheckboxXRays}
              setIsVisible={setIsVisibleCheckboxXRays}
            />
          </div>

          <div className="second-cards">
            {isVisibleCheckboxHistory && (
              <CheckboxMedicalHistory
                selectedOptions={selectedHistoryOptions}
                onSelectedOptionsChange={handleSelectedHistoryChange}
                handleButtonClick={toggleVisibleHistoryForm}
                showSpecificForm={showSpecificHistoryForm}
              />
            )}

            {isVisibleCheckboxPhysical && (
              <CheckboxPhysicalExam
                selectedOptions={selectedPhysicalOptions}
                onSelectedOptionsChange={handleSelectedPhysicalChange}
                handleButtonClick={toggleVisiblePhysicalForm}
                showSpecificForm={showSpecificPhysicalForm}
              />
            )}
            {isVisibleCheckboxXRays && (
              <CheckboxXRays
                selectedOptions={selectedXRaysyOptions}
                onSelectedOptionsChange={handleSelectedXRaysChange}
                handleButtonClick={toggleVisibleXRaysForm}
                showSpecificForm={showSpecificXRaysForm}
              />
            )}
          </div>

          <div className="third-cards">
            {showSpecificHistoryForm && (
              <>
                {selectedHistoryOptions["Anamnèse mono-traumatisme"] && (
                  <MonoTraumaHistory
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Anamnèse mono-trauma", true)}
                  />
                )}
                {selectedHistoryOptions["Co-morbidités - en construction"] && (
                  <ChronicConditions
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Co-morbidités", true)}
                  />
                )}
                {selectedHistoryOptions["Genou: Oxford Knee Score"] && (
                  <OxfordKneeScore
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Oxford Knee Score", true)}
                  />
                )}
                {selectedHistoryOptions["Hanche: Harris score (anamnèse)"] && (
                  <HarrisHipClinicalScore
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Harris Hip Score", true)}
                  />
                )}
                {selectedHistoryOptions["Traitement en cours - en construction"] && (
                  <MedicalTreatment
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Traitement en cours", true)}
                  />
                )}
              </>
            )}

            {showSpecificPhysicalForm && (
              <>
                {selectedPhysicalOptions.Généralités && (
                  <GeneralPhysicalExam
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Généralités", false)}
                  />
                )}
                {selectedPhysicalOptions.Hanche && (
                  <Hip
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Hanche", false)}
                  />
                )}
                {selectedPhysicalOptions.Genou && (
                  <Knee
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Genou", false)}
                  />
                )}
                {selectedPhysicalOptions["Status neuro-vasculaire"] && (
                  <NVstatusMI
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Neuro-vasc", false)}
                  />
                )}
                {selectedPhysicalOptions.Cheville && (
                  <Ankle
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Cheville", false)}
                  />
                )}
              </>
            )}

            {showSpecificXRaysForm && (
              <>
                {selectedXRaysyOptions["Pelvis, traumatologie"] && (
                  <PelvisXRayTraumat
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Bilan radiologique: pelvis", false)}
                  />
                )}
                {selectedXRaysyOptions["Fémur, traumatologie"] && (
                  <FemurXRayTraumat
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Bilan radiologique: fémur proximal", false)}
                  />
                )}
                {selectedXRaysyOptions["Tibia, péroné & rotule, traumatologie"] && (
                  <TibiaXRayTraumat
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Bilan radiologique: tibia, péroné & rotule", false)}
                  />
                )}
                {selectedXRaysyOptions["Hanche native"] && (
                  <HipXray
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Bilan radiologique: hanche native", false)}
                  />
                )}
                {selectedXRaysyOptions["Hanche prothétique"] && (
                  <HipRecon
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Bilan radiologique: hanche prothétique", false)}
                  />
                )}
                {selectedXRaysyOptions["Genou natif"] && (
                  <KneeXray
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Bilan radiologique: genou natif", false)}
                  />
                )}
                {selectedXRaysyOptions["Genou prothétique"] && (
                  <KneeRecon
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Bilan radiologique: genou prothétique", false)}
                  />
                )}
                {selectedXRaysyOptions["Alignement membres inférieurs"] && (
                  <FullLowerLimbXray
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Alignement membres inférieurs", false)}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}

      {isFormSended && (
        isText ? (
          <TextSummary setIsFormSended={setIsFormSended} formDetails={formDetails} />
        ) : (
          <Summary nameSpecificCard={nameForm} setIsFormSended={setIsFormSended} formDetails={formDetails} />
        )
      )}
    </>
  );
}

export default LowerLimb;