import React, { useState } from "react";
import "../../../styles/app.css";

function FullLowerLimbXray({setNameForm, onFormSubmit}) {
  const [formState, setFormState] = useState({
    legDisHip: "",
    quantLenghtHip: "",

    LowerLimbAlignementLeft:"",
    LowerLimbAlignementRight:"",


    DistalFemoralAngleLeft:"",
    ProximalTibialangleLeft:"",

    DistalFemoralAngleRight:"",
    ProximalTibialangleRight:"",

    TibialSlopeLeft:"",
    TibialSlopeRight:"",


    
  });

  const [showXray, setshowXray] = useState(false);

  const handleChangeshowXray = (event) => {
    setshowXray(event.target.checked);
  }

  const [LowerLimbXray, setLowerLimbXray] = useState({
    "Longs axes face":false,
    "Longs axes profil":false,
    "Fémur gauche face":false,
    "Fémur droit face":false,
    "Tibia gauche face":false,
    "Tibia gauche profil":false,
    "Tibia droit face":false,
    "Tibia droit profil":false,
  });



  const handleCheckboxChangLowerLimbXray = (event) => {
    const { name, checked } = event.target;
     
      setLowerLimbXray(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState({
      ...formState,
      [name]: type === "checkbox" ? checked : value,
    });
  };





  const handleSubmit = (e) => {
    e.preventDefault();
    // Prepare form details for summary
    setNameForm("Alignement membres inférieurs");

         // Function to convert the state of the checkboxes into a readable string
         const formatCheckboxStateToString = (checkboxState) => {
          const selectedOptions = Object.entries(checkboxState)
              .filter(([_, isChecked]) => isChecked)
              .map(([key, _]) => key) // Get only the keys (option names)
              .join(", ");
    
          return selectedOptions || ""; // If no options are selected, return "None selected"
      };
    
      const LowerLimbText = formatCheckboxStateToString(LowerLimbXray);
    


    const formDetails = {
      // Here we convert the form state into a suitable format for display
      "Bilan radiologique": LowerLimbText,

      "Longueur membres inférieur": formState.legDisHip + (formState.quantLenghtHip ? `${formState.quantLenghtHip} mm` : ""),
      
      "Alignement mécanique membre inférieur gauche": formState.LowerLimbAlignementLeft,
      "Alignement mécanique membre inférieur droit": formState.LowerLimbAlignementRight,

      "Distal Femoral angle (mechanical), gauche": formState.DistalFemoralAngleLeft,
      "Distal Femoral angle (mechanical), droit": formState.DistalFemoralAngleRight,

      "Proximal Tibial Angle (mechanical), gauche": formState.ProximalTibialangleLeft,
      "Proximal Tibial Angle (mechanical), droit": formState.ProximalTibialangleRight,
  
      "Pente tibiale, gauche": formState.TibialSlopeLeft,
      "Pente tibiale, droite": formState.TibialSlopeRight
  
    };




    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object


    // Call the function passed from App.js and pass the details
    onFormSubmit(filteredFormDetails); // Make sure to pass this function correctly from App.js

};

  return (
    <div className="card">
      <p className="title">Bilan radiologique genou</p>
      <form className="specific-form" onSubmit={handleSubmit}>


      <div>
   
   <input
       type="checkbox"
       name="showXray"
       onChange={handleChangeshowXray}
       
     />
     <label>Renseigner incidences radiologiques</label>

   </div>

   {showXray && (
      <div className="checkbox-options">
      {Object.entries(LowerLimbXray).map(([key, value]) => (
        <div key={key}>
          <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangLowerLimbXray} />
          <label>{key}</label>
        </div>
      ))}
      </div>


      )}

<div>     
        <select name="legDisHip" className="select-input" value={formState.legDisHip} onChange={handleInputChange} >
          <option value="">longueur membres inférieurs:</option>
          <option value="symétrique">symétrique</option>
          <option value="gauche > droit ">gauche {'>'} droit</option>
          <option value="droit > gauche ">droit {'>'} gauche</option>
        </select>
      </div>

      {formState.legDisHip === "gauche > droit " && (
           <div>
           <label>Inégalité de longueur:</label>
           <input type="number" className="number-input" name="quantLenghtHip" min="0" max="500" placeholder = "mm" value={formState.quantLenghtHip} onChange={handleInputChange} required/>
         </div>
      )
      
      }

      {formState.legDisHip === "droit > gauche " && (
           <div>
           <label>Inégalité de longueur:</label>
           <input type="number" className="number-input" name="quantLenghtHip" min="0" max="500" placeholder = "mm" value={formState.quantLenghtHip} onChange={handleInputChange} required/>
         </div>
      )
      
      }
      
      
      <div>
      <label className = "title-label-within-card" > Alignement mécanique membres inférieurs: </label>
  
      <div>
          <input type="number" className="number-input" name="LowerLimbAlignementLeft" placeholder = "Gauche"  value={formState.LowerLimbAlignementLeft} onChange={handleInputChange}/>
     
       <input type="number" className="number-input" name="LowerLimbAlignementRight" placeholder = "Droite"  value={formState.LowerLimbAlignementRight} onChange={handleInputChange}/>
      
      
      </div>
      </div>

      <div>
      <label className = "title-label-within-card" > Distal Femoral Angle {"("}mecanical{")"}: </label>
  
      <div>
          <input type="number" className="number-input" name="DistalFemoralAngleLeft" placeholder = "Gauche"  value={formState.DistalFemoralAngleLeft} onChange={handleInputChange}/>
     
       <input type="number" className="number-input" name="DistalFemoralAngleRight" placeholder = "Droite"  value={formState.DistalFemoralAngleRight} onChange={handleInputChange}/>
      
      </div>
      </div>

      <div>
      <label className = "title-label-within-card" > Proximal tibial angle {"("}mecanical{")"}: </label>
  
      <div>
          <input type="number" className="number-input" name="ProximalTibialangleLeft" placeholder = "Gauche"  value={formState.ProximalTibialangleLeft} onChange={handleInputChange}/>
     
       <input type="number" className="number-input" name="ProximalTibialangleRight" placeholder = "Droite"  value={formState.ProximalTibialangleRight} onChange={handleInputChange}/>
      
      </div>
      </div>

      <div>
      <label className = "title-label-within-card" > Tibial Slope: </label>
  
      <div>
          <input type="number" className="number-input" name="TibialSlopeLeft" placeholder = "Gauche"  value={formState.TibialSlopeLeft} onChange={handleInputChange}/>
     
       <input type="number" className="number-input" name="TibialSlopeRight" placeholder = "Droite"  value={formState.TibialSlopeRight} onChange={handleInputChange}/>
      
      </div>
      </div>


     



     
      




        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default FullLowerLimbXray;