import React from "react";
import "../styles/app.css";
import useLockBodyScroll from "./useLockBodyScroll";

function Modal({ imageSrc, onClose }) {
    //To lock the body scroll
    useLockBodyScroll();
    // This function handles clicks outside the modal content. If clicked outside the modal content, it closes the modal
    const handleOutsideClick = (event) => {
        // Check if the clicked element has the class modal and also this ensures the click was outside the modal content
        if (event.target.className === "modal") {
        onClose(); //To close the modal.
        }
    };

    return (
        <div className="modal" onClick={handleOutsideClick}>
        <div className="modal-content">
            <span className="close" onClick={onClose}>&times;</span>{/* X simbol --> &times */}
            <img className="specific-image-modal" src={imageSrc} alt="Modal content" />
        </div>
        </div>
    );
}


export default Modal;