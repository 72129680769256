import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./styles/app.css";
import Navbar from "./other-components/Navbar";
import Home from "./other-components/Home";
import UpperLimb from "./UpperLimb/UpperLimb";
import LowerLimb from "./LowerLimb/LowerLimb";

function App() {
  return (
    <Router>
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/upper-limb" element={<UpperLimb />} />
          <Route path="/lower-limb" element={<LowerLimb />} />
          {/* In the future, we put more routes.... */}
          <Route path="*" element={<Navigate to="/Home" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;