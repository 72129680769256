import React, { useState } from "react";
import "../../../styles/app.css";
import Modal from "../../../other-components/Modal.js";

function HumerusXRayTraumat({setNameForm, onFormSubmit}) {


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [specificImage, setSpecificImage] = useState("");

  const openModalWithSpecificImage = (image) => {
    setSpecificImage(image);
    setIsModalOpen(true);
  }

  const [formState, setFormState] = useState({
    xRayWorkUp: '',
    side: '',
    Proximal_Fracture_Neer: '',
    HeadSplit:'',
    Diaphyseal_Fracture: '',
    Distal_Fracture_AO:'',
    Distal_Fracture_Jupiter:'',
  });

  const [XRayWorkUp, setXRayWorkUp] = useState({
    "épaule face":false,
    "épaule Neer":false,
    "épaule axiale":false,
    "humérus face": false,
    "humérus profil":false,
    "coude face": false,
    "coude profil": false,
    "CT-scan": false,
  
  });

  const handleCheckboxChangeXRayWorkUp = (event) => {
    const { name, checked } = event.target;
     
      setXRayWorkUp(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };

  const [PartsProximalHumerus, setPartsProximalHumerus] = useState({
    "grande tuberosité":false,
    "petite tubérosité":false,
    "col anatomique":false,
    "col chirurgical": false,
  
  });

  const handleCheckboxChangePartsProximalHumerus = (event) => {
    const { name, checked } = event.target;
     
      setPartsProximalHumerus(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };



  const [showXray, setshowXray] = useState(false);

  const handleChangeshowXray = (event) => {
    setshowXray(event.target.checked);
  }




 



  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Humérus");
 // Function to convert the state of the checkboxes into a readable string
    const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const XRayWorkUpText = formatCheckboxStateToString(XRayWorkUp);
  const PartsProximalHumerusText = formatCheckboxStateToString(PartsProximalHumerus);
  


    // Prepare form details for summary
    const formDetails = {
      "Humérus": formState.side,
      "Bilan radiologique": XRayWorkUpText,
      "Fracture humérus proximal": [formState.Proximal_Fracture_Neer,"(", PartsProximalHumerusText, ")", formState.HeadSplit].filter(function(val) {
        return val;
      }).join(' '),
      "Fracture humérus diaphysaire": formState.Diaphyseal_Fracture,
      "Fracture humérus distal": formState.Distal_Fracture_AO,
      "Fracture humérus distal selon Jupiter": formState.Distal_Fracture_Jupiter,
      
    };


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

   


    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };






  return (
    <div className="card">
      <p className="title">Humérus, bilan radiologique</p>
      <form className="specific-form" onSubmit={handleSubmit}>



      <div className="checkbox-options">
            <div>
             <input type="radio" name="sideProxFemur" value="gauche" checked={formState.sideProxFemur === "gauche"} onChange={handleInputChange} />
            <label> gauche </label>
         
            <input type="radio" name="sideProxFemur" value="droit" checked={formState.sideProxFemur === "droit"} onChange={handleInputChange} />
            <label> droit </label>
            </div>
        </div>

      <div>

      <br></br>
   
   <input
       type="checkbox"
       name="showXray"
       onChange={handleChangeshowXray}
       
     />
     <label>Renseigner incidences radiologiques</label>

   </div>
   

   {showXray && (
       <div className="checkbox-options">
       
       {Object.entries(XRayWorkUp).map(([key, value]) => (
         <div key={key}>
           <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeXRayWorkUp} />
           <label>{key}</label>
         </div>
       ))}
     </div>

   )}
 
   

      <br></br>

        <div>
        
          <select name="Proximal_Fracture_Neer" className="select-input" value={formState.Proximal_Fracture_Neer} onChange={handleInputChange} >
            <option value="">Fracture humérus proximal selon Neer:</option>
            <option value="deux parts selon Neer">Deux parts</option>
            <option value="trois parts selon Neer">Trois parts</option>
            <option value="quatre parts selon Neer">quatre parts</option>
            <option value="associée à une luxation, lésion type V selon Neer">Fracture-luxation</option>
           
          </select>
        </div>

        {formState.Proximal_Fracture_Neer && (
              <div className="checkbox-options">
              {Object.entries(PartsProximalHumerus).map(([key, value]) => (
            <div key={key}>
              <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangePartsProximalHumerus} />
              <label>{key}</label>
            </div>
          ))}
          </div>

          )}

            {formState.Proximal_Fracture_Neer && (
             
             <div>
          <label>Head Split: &emsp; </label>
          
          <input type="radio" name="HeadSplit" value="pas de head split" checked={formState.HeadSplit === "pas de head split"} onChange={handleInputChange} />
            <label> - </label>
         
            <input type="radio" name="HeadSplit" value="présence d'un head split" checked={formState.HeadSplit === "présence d'un head split"} onChange={handleInputChange} />
            <label> + </label>
        </div>

          )}




        <div>
    
          <select name="Diaphyseal_Fracture" className="select-input" value={formState.Diaphyseal_Fracture} onChange={handleInputChange} >
            <option value="">Fracture diaphysaire:</option>
            <option value="spiroide (Classification AO 12-A1)">spiroide (Classification AO 12-A1)</option>
            <option value="oblique (Classification AO 12-A2)">oblique (Classification AO 12-A2)</option>
            <option value="transverse (Classification AO 12-A3)">transverse (Classification AO 12-A3)</option>
            <option value="type wedge (Classification AO 12-B2)">type wedge (Classification AO 12-B2)</option>
            <option value="type wedge, fragmentaire (Classification AO 12-B3)">type wedge, fragmentaire (Classification AO 12-B3)</option>
            <option value="segmentaire (Classification AO 12-C2)">segmentaire (Classification AO 12-C2)</option>
            <option value="segmentaire, plurifragmentaire (Classification AO 12-C3)">segmentaire, plurifragmentaire (Classification AO 12-C3)</option>
            </select>
            </div>


          
            <div>
          <select name="Distal_Fracture_AO" className="select-input" value={formState.Distal_Fracture_AO} onChange={handleInputChange} >
            <option value="">Fracture humérus distal selon l'AO:</option>
            <option value="épicondyle latéral par avulsion (extra-articulaire), AO 13A1.1"> épicondyle latéral par avulsion {'('}extra-articulaire{')'}, AO 13A1.1</option>
            <option value="épicondyle médial par avulsion (extra-articulaire), AO 13A1.2">épicondyle médial par avulsion {'('}extra-articulaire{')'}, AO 13A1.2</option>
            <option value="extra-articulaire simple, spiroïde, AO 13A2.1">extra-articulaire simple, spiroïde, AO 13A2.1</option>
            <option value="extra-articulaire simple, oblique, AO 13A2.2">extra-articulaire simple, oblique, AO 13A2.2</option>
            <option value="extra-articulaire simple, transverse, AO 13A2.3">extra-articulaire simple, transverse, AO 13A2.3</option>
            <option value="extra-articulaire avec wedge intact, AO 13A3.1">extra-articulaire avec wedge intact, AO 13A3.1</option>
            <option value="extra-articulaire avec wedge fragmentaire, AO 13A3.2">extra-articulaire avec wedge fragmentaire, AO 13A3.2</option>
            <option value="extra-articulaire multi-fragmentaire, AO 13A3.3">extra-articulaire multi-fragmentaire, AO 13A3.3</option>
            <option value="intra-articulaire partielle, sagittale latérale, AO 13B1">intra-articulaire partielle, sagittale latérale, AO 13B1</option>
            <option value="intra-articulaire partielle médiale, transtrochléaire simple, AO 13B2.1/2">intra-articulaire partielle médiale, transtrochléaire simple, AO 13B2.1/2</option>
            <option value="intra-articulaire partielle médiale, transtrochléaire complexe, AO 13B2.3">intra-articulaire partielle médiale, transtrochléaire complexe, AO 13B2.3</option>
            <option value="intra-articulaire partielle, plan frontal/coronal, AO 13B3">intra-articulaire partielle, plan frontal/coronal, AO 13B3</option>
            <option value="intra-articulaire simple, métaphyse simple, AO 13C1">intra-articulaire simple, métaphyse simple, AO 13C1</option>
            <option value="intra-articulaire simple, métaphyse complexe, AO 13C2">intra-articulaire simple, métaphyse complexe, AO 13C2</option>
            <option value="intra-articulaire complexe, métaphyse complexe, AO 13C3">intra-articulaire complexe, métaphyse complexe, AO 13C3</option>

            </select>

        </div>

        <div>
          <select name="Distal_Fracture_Jupiter" className="select-input" value={formState.Distal_Fracture_Jupiter} onChange={handleInputChange} >
            <option value="">Fracture humérus distal selon Jupiter:</option>
            <option value="type high-T"> type high-T</option>
            <option value="type low-T">type low-T</option>
            <option value="type Y">type Y</option>
            <option value="type H">type H</option>
            <option value="type lambda médial">type lambda médial</option>
            <option value="type lambda latéral">type lambda latéral</option>
            <option value="T multiplan">T multiplan</option>
          
            </select>

        </div>


        <button type="submit" className="button send">Send</button>
      </form>


      <br></br>

     
    
    </div>
  );
}

export default HumerusXRayTraumat;