// Allergies.js
import React, { useState } from 'react';
import '../../../styles/app.css';

function MonoTraumaHistory({ setNameForm, onFormSubmit }) {
  const [formState, setFormState] = useState({
    sex:'Patient(s)',
    age: 'XX',
    medicalCondition: '',
    injuryType: '',
    injuryLocation: '',
    injurySide: '',
    injuryRedFlagsCranialTrauma:'',
    injuryRedFlagsCervicalTrauma:'',
    injuryRedFlagsLOC:''
  });



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    setNameForm('Patient Allergy Information');


    const formDetails = {
      'L1': formState.sex + " de " + formState.age + " ans " + formState.medicalCondition + " victime " + formState.injuryType
      + " avec traumatisme " + formState.injuryLocation + " à " + formState.injurySide,
      'L2': [  formState.injuryRedFlagsCranialTrauma, formState.injuryRedFlagsCervicalTrauma,formState.injuryRedFlagsLOC]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),
      

      
    };



    onFormSubmit(formDetails);
  };

  return (
    <div className="card">
      <p className="title">Anamnèse mono-traumatisme</p>
      <form className="specific-form" onSubmit={handleSubmit}>

      <div>
          
       


        <div className="radio-options">
          
            <input type="radio" name="sex" value="Patiente" checked={formState.sex=== "Patiente"} onChange={handleInputChange}/>
            <label>♀</label>
         
            <input type="radio" name="sex" value="Patient" checked={formState.sex === "Patient"} onChange={handleInputChange} />
            <label> ♂ </label>

            <input type="number" className="number-input" name="age" min="0" max="120" placeholder= "age" value={formState.age} onChange={handleInputChange} />

          
            </div>
        </div>
        

        <div>
        
          <select name="medicalCondition" className="select-input" value={formState.medicalCondition} onChange={handleInputChange} required >
            <option value="">Co-morbidités:</option>
            <option value="sans co-morbidités">sans co-morbidités</option>
            <option value="aux co-morbidités sous-mentionnées">avec co-morbidités {'('}à détailler dans formulaire distinct{')'}</option>
            
          </select>
        </div>
      

        <div>
        
        <select name="injuryType" className="select-input" value={formState.injuryType} onChange={handleInputChange} required >
          <option value="">Type traumatisme:</option>
          <option value="d'une chute mécanique de sa hauteur">chute mécanique de sa hauteur</option>
          <option value="d'une chute de sa hauteur sans témoin">chute de sa hauteur sans témoin</option>
          <option value="d'une chute de sa hauteur d'origine indéterminée">chute de sa hauteur d'origine indéterminée</option>
          <option value="d'une chute de sa hauteur sans prodrome">chute de sa hauteur sans prodrome</option>
          <option value="">-----</option>
          <option value="d'un AVP basse cinétique(PRECISION)">AVP basse cinétique</option>
          <option value="d'un AVP cinétique moyenne (PRECISION)">AVP cinétique moyenne</option>
          <option value="d'un AVP haute cinétique (PRECISION)">AVP haute cinétique</option>
          
        </select>
      </div>

      <div className="radio-options">
          <p>Localisation traumatisme:</p>
          <div>
            <input type="radio" name="injuryLocation" value="de la hanche" checked={formState.injuryLocation === "de la hanche"} onChange={handleInputChange}/>
            <label> Hanche</label>
            </div>
        
            <div>
            <input type="radio" name="injuryLocation" value="de la cuisse" checked={formState.injuryLocation === "de la cuisse"} onChange={handleInputChange} />
            <label> Cuisse </label>
            </div>

            <div>
            <input type="radio" name="injuryLocation" value="du genou" checked={formState.injuryLocation === "du genou"} onChange={handleInputChange} />
            <label> Genou </label>
            </div>


            <div>
            <input type="radio" name="injuryLocation" value="de la jambe" checked={formState.injuryLocation === "de la jambe"} onChange={handleInputChange} />
            <label> Jambe </label>
            </div>

            <div>
            <input type="radio" name="injuryLocation" value="de la cheville" checked={formState.injuryLocation === "de la cheville"} onChange={handleInputChange} />
            <label> Cheville </label>
            </div>

            <div>
            <input type="radio" name="injuryLocation" value="du pied" checked={formState.injuryLocation === "du pied"} onChange={handleInputChange} />
            <label> Pied </label>
            </div>


        </div>
        <br></br>
      
      
        <div>
         
          <input type="radio" name="injurySide" value="gauche" checked={formState.injurySide === "gauche"} onChange={handleInputChange} />
          <label> gauche </label>
         
            <input type="radio" name="injurySide" value="droit(e)" checked={formState.injurySide === "droit(e)"} onChange={handleInputChange} />
            <label> droit </label>
        </div>

        <br></br>


        <div>
        <label>Traumatisme crânien: &emsp; &nbsp;
         <input type="radio" name="injuryRedFlagsCranialTrauma" value="Pas de traumatisme crânien" checked={formState.injuryRedFlagsCranialTrauma === "Pas de traumatisme crânien"} onChange={handleInputChange} />
         <label> - </label>

         <input type="radio" name="injuryRedFlagsCranialTrauma" value="Anamanèse positive pour un traumatisme crânien: A PRECISER" checked={formState.injuryRedFlagsCranialTrauma === "Anamanèse positive pour un traumatisme crânien: A PRECISER"} onChange={handleInputChange} />
         <label> + </label>
        
        
           </label>
       </div>

       <div>
       <label>Traumatisme cervical: &nbsp; &nbsp; &nbsp; 

         <input type="radio" name="injuryRedFlagsCervicalTrauma" value="Pas de traumatisme cervical" checked={formState.injuryRedFlagsCervicalTrauma === "Pas de traumatisme cervical"} onChange={handleInputChange} />
           <label> - </label>
        
           <input type="radio" name="injuryRedFlagsCervicalTrauma" value="Anamanèse positive pour un traumatisme cervical: A PRECISER" checked={formState.injuryRedFlagsCervicalTrauma === "Anamanèse positive pour un traumatisme cervical: A PRECISER"} onChange={handleInputChange} />
           <label> + </label>

           </label>
       </div>



       
       <div>
       <label>Perte de connaissance: &emsp; 

         <input type="radio" name="injuryRedFlagsLOC" value="Pas de perte de connaissance" checked={formState.injuryRedFlagsLOC === "Pas de perte de connaissance"} onChange={handleInputChange} />
           <label> - </label>
        
           <input type="radio" name="injuryRedFlagsLOC" value="Anamanèse positive pour une perte de connaissance: A PRECISER" checked={formState.injuryRedFlagsLOC === "Anamanèse positive pour une perte de connaissance: A PRECISER"} onChange={handleInputChange} />
           <label> + </label>

           </label>
       </div>
      

        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default MonoTraumaHistory;